import { AddressElement, PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Grid, styled, Typography } from "@mui/material";
import Spinner from "../../reusableComponents/spinner/spinner";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { validatCard } from "../../../resources/payments";
import { processPayment } from "../../../store/thunks/paymentThunk";
import dayjs from "dayjs";
import { LVLpmThemeButton } from "../../../utilities/constants";
import { updateBooking } from "../../../store/thunks/bookingsThunk";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { createUserMessageList } from "../../../store/thunks/messagesThunk";

import { useNavigate } from "react-router-dom";
import { setCreatedUserMessageList } from "../../../store";
import { displayConsoleError } from "../../../utilities/helperFunctions";

interface UpdateBookingFormProps {
  handleCloseUpdateForm: (event: any) => void;
  paymentIntentId: string;
  nights: string;
  guests: number;
  propertyDetails: any;
  bookingDetails: any;
  check_in: any;
  check_out: any;
  bookingFees: any;
}

const UpdateBookingForm = (props: UpdateBookingFormProps) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const {
    handleCloseUpdateForm,
    paymentIntentId,
    nights,
    guests,
    check_out,
    check_in,
    propertyDetails,
    bookingDetails,
    bookingFees,
  } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const { userInfo, supportUsers } = useAppSelector(
    (state: any) => state.authentication
  );

  const [submited, setSubmitted] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});
  const [address, setAddress] = useState<any>({});

  const [state, setState] = useState<any>({
    cardNumber: "",
    cardExpiration: "",
    cardCvc: "",
    cardName: "",
    cardIssuer: "",
    zipCode: "",
  });

  const [message, setMessage] = useState<any>(null);
  const [updateBookingIsProcessing, setUpdateBookingIsProcessing] =
    useState(false);

  const cardInfoVerification = () => {
    const cardErros = validatCard(state);

    setErrors(cardErros);

    if (cardErros.isValid) {
      return true;
    }
    return false;
  };

  const handleCloseUpdateDialog = (event: string) => {
    handleCloseUpdateForm(event);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setSubmitted(true);
    const check = cardInfoVerification();

    if (check === false) return;

    let tempTotalPrice =
      Math.round(
        Number(
          bookingFees?.newTotalPrice + Number(bookingDetails.total_price)
        ) * 100
      ) / 100;
    let tempTaxes =
      Math.round(
        Number(bookingFees?.taxes + Number(bookingDetails.taxes)) * 100
      ) / 100;
    let tempLvlpm_fee =
      Math.round(
        Number(bookingFees?.lvlpmFee + Number(bookingDetails.lvlpm_fee)) * 100
      ) / 100;
    let tempstay_price =
      Math.round(
        Number(bookingFees?.stayPrice + Number(bookingDetails.stay_price)) * 100
      ) / 100;
    let tempprice_per_day_ca =
      Math.round(
        Number(
          bookingFees?.pricePerDay + Number(bookingDetails.price_per_day_ca)
        ) * 100
      ) / 100;

    const updateBookingPaylod = {
      user_id: userInfo?.user_id,
      email: userInfo?.email,
      property_name: propertyDetails.property_name,
      property_id: propertyDetails.property_id,
      hostfully_id: propertyDetails.hostfully_property_id,
      booking_id: bookingDetails.booking_id,
      check_in: dayjs(bookingDetails.check_in).format("YYYY-MM-DDTHH:mm:ss"),
      new_check_in: dayjs(check_in).format("YYYY-MM-DDTHH:mm:ss"),
      new_check_out: dayjs(check_out).format("YYYY-MM-DDTHH:mm:ss"),
      cardAddres: address,
      allTotalPrice: tempTotalPrice,
      allTaxes: tempTaxes,
      allLvlpm_fee: tempLvlpm_fee,
      all_price_per_day_ca: tempprice_per_day_ca,
      all_stay_price: tempstay_price,
      newPaymentIntentId: paymentIntentId,
      allPaymentIntentId: `${bookingDetails.payment_intent_id},${paymentIntentId}`,
      all_nights: nights + bookingDetails.nights,
      guests: guests,
      lead_id: bookingDetails.hostfully_lead_id,
      bookingFees: bookingFees,
    };

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setUpdateBookingIsProcessing(true);

    await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}`,
        },
        redirect: "if_required",
      })
      .then(function (result) {
        if (result.error) {
          if (
            result.error?.type === "card_error" ||
            result.error?.type === "validation_error"
          ) {
            setMessage(result.error?.message);
          } else {
            setMessage("An unexpected error occured.");
          }
        } else {
          dispatch(
            updateBooking({ update_booking_payload: updateBookingPaylod })
          )
            .then(async (paymentResult: any) => {
              setUpdateBookingIsProcessing(false);
              handleCloseUpdateDialog(
                "Booking Update has been completed successfully"
              );
            })
            .catch((error) => {
              setUpdateBookingIsProcessing(false);
              displayConsoleError("processPayment", error);
              handleCloseUpdateDialog(
                "Unable to complete booking update. Please try again later or contact customer support"
              );
            });
        }
        return result;
      });
  };

  const chatWithSupport = () => {
    const supportDetail = supportUsers
      ? supportUsers?.find((support: any) => support.first_name === "Booking")
      : null;

    const temp_mesage_list_payload = {
      subtitle: bookingDetails?.property_name,
      sender_id: userInfo.user_id,
      reciever_id: supportDetail ? supportDetail?.user_id : 74,
    };
    dispatch(
      createUserMessageList({ message_list_payload: temp_mesage_list_payload })
    )
      .then((result: any) => {
        if (result?.payload?.message_list) {
          dispatch(setCreatedUserMessageList(result?.payload?.message_list));
        }
        handleCloseUpdateForm("");
        navigate("/messages");
      })
      .catch((error) => {
        displayConsoleError("messageHost", error);
        handleCloseUpdateForm("");
        navigate("/messages");
      });
  };

  return (
    <Grid item xs={12} sx={{ padding: "15px" }}>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement />
        <AddressElement
          className={"text-sm text-gray-700 border rounded"}
          options={{ mode: "billing" }}
          onChange={(event) => {
            setAddress(event.value);
            setState((prev: any) => ({
              ...prev,
              cardName: event.value.name ? event.value.name : "",
              zipCode: event.value.address.postal_code
                ? event.value.address.postal_code
                : "",
            }));
          }}
        />
        {submited && !errors?.isValid && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Typography style={{ color: "red" }}>{errors.message}</Typography>
          </Grid>
        )}
        <Grid container sx={{ marginTop: "2%", gap: 1 }}>
          <LVLpmThemeButton
            variant="contained"
            onClick={() => chatWithSupport()}
          >
            Chat With Support
          </LVLpmThemeButton>
          <LVLpmThemeButton
            disabled={updateBookingIsProcessing || !stripe || !elements}
            variant="contained"
            onClick={(e) => handleSubmit(e)}
          >
            Confirm Update
          </LVLpmThemeButton>
        </Grid>
      </form>
    </Grid>
  );
};

export default UpdateBookingForm;
