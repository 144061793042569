import { createSlice } from "@reduxjs/toolkit";
import {
  getOwnerProperties,
  addOwnerProperty,
  getAllPropertyListingType,
  getAllPropertyRoomType,
  getAllCountries,
  uploadPropertyImages,
  getPropertyImages,
  getSpecificProperty,
} from "../../thunks/propertyThunk";

interface PropertySlice {
  propertyLoading: Boolean;
  allCountries: any[] | null;
  allPropertyListingTypes: any[] | null;
  allPropertyRoomTypes: any[] | null;
  error: any;
  response: any;
  ownerProperties: Array<Object>;
  selectedReservePropertyDetails: any;
  newProperty: Object;
  addPropertyLoading: Boolean;
  imageUpload: any;
  specificPropertyLoading: Boolean;
}

const initialState: PropertySlice = {
  propertyLoading: false,
  error: null,
  response: null,
  ownerProperties: [],
  newProperty: {},
  allPropertyListingTypes: [],
  allPropertyRoomTypes: [],
  allCountries: [],
  addPropertyLoading: false,
  imageUpload: null,
  selectedReservePropertyDetails: null,
  specificPropertyLoading: false,
};

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getOwnerProperties.pending, (state) => {
      state.propertyLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getOwnerProperties.fulfilled, (state, action) => {
      state.propertyLoading = false;
      state.ownerProperties = action.payload.owner_properties;
    });
    builder.addCase(getOwnerProperties.rejected, (state, action) => {
      state.propertyLoading = false;
      state.error = action.payload;
    });

    builder.addCase(addOwnerProperty.pending, (state) => {
      state.addPropertyLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(addOwnerProperty.fulfilled, (state, action) => {
      state.addPropertyLoading = false;
      state.newProperty = action.payload;
    });
    builder.addCase(addOwnerProperty.rejected, (state, action) => {
      state.addPropertyLoading = false;
      // state.error = action.payload;
    });

    builder.addCase(getAllPropertyListingType.pending, (state) => {
      state.propertyLoading = true;
      state.error = null;
    });
    builder.addCase(getAllPropertyListingType.fulfilled, (state, action) => {
      state.propertyLoading = true;
      state.allPropertyListingTypes = action.payload.all_property_listing_types;
    });
    builder.addCase(getAllPropertyListingType.rejected, (state, action) => {
      state.propertyLoading = true;
      state.error = action.payload;
    });

    builder.addCase(getAllPropertyRoomType.pending, (state) => {
      state.propertyLoading = true;
      state.error = null;
    });
    builder.addCase(getAllPropertyRoomType.fulfilled, (state, action) => {
      state.propertyLoading = true;
      state.allPropertyRoomTypes = action.payload.all_property_room_types;
    });
    builder.addCase(getAllPropertyRoomType.rejected, (state, action) => {
      state.propertyLoading = true;
      state.error = action.payload;
    });

    builder.addCase(getAllCountries.pending, (state) => {
      state.propertyLoading = true;
      state.error = null;
    });
    builder.addCase(getAllCountries.fulfilled, (state, action) => {
      state.propertyLoading = true;
      state.allCountries = action.payload.countries;
    });
    builder.addCase(getAllCountries.rejected, (state, action) => {
      state.propertyLoading = true;
      state.error = action.payload;
    });

    builder.addCase(uploadPropertyImages.pending, (state) => {
      state.addPropertyLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(uploadPropertyImages.fulfilled, (state, action) => {
      state.addPropertyLoading = false;
      state.imageUpload = action.payload;
    });
    builder.addCase(uploadPropertyImages.rejected, (state, action) => {
      state.addPropertyLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getPropertyImages.pending, (state) => {
      state.propertyLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getPropertyImages.fulfilled, (state, action) => {
      state.propertyLoading = false;
      state.ownerProperties = action.payload.owner_properties;
    });
    builder.addCase(getPropertyImages.rejected, (state, action) => {
      state.propertyLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getSpecificProperty.pending, (state) => {
      state.specificPropertyLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getSpecificProperty.fulfilled, (state, action) => {
      state.specificPropertyLoading = false;
      state.selectedReservePropertyDetails = action.payload.property_details;
    });
    builder.addCase(getSpecificProperty.rejected, (state, action) => {
      state.specificPropertyLoading = false;
      state.error = action.payload;
    });
  },
});

export const {} = propertySlice.actions;

export const propertyReducer = propertySlice.reducer;
