import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import Calendar from "./CalendarComponent";

function createData(
  property: string,
  guest: string,
  checkInOut: string,
  bookingStatus: string,
  price: string,
  days: number
) {
  return {
    property,
    guest,
    checkInOut,
    bookingStatus,
    price,
    days,
  };
}

const rows = [
  createData(
    "My Property",
    "Jack Hall",
    "09/13/24 - 09/15/24",
    "Booked",
    "90/Night",
    3
  ),
  createData(
    "My Property",
    "Sam Doe",
    "09/16/24 - 09/20/24",
    "Booked",
    "90/Night",
    4
  ),
];

const CalendarPage = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, error, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
  }, [navigate, loggedIn]);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        padding: "0 20px 0 80px",
        width: "100%",
      }}
    >
      <AccountDrawer handleDrawer={handleDrawer} open={open} />
      <Grid container>
        <Grid item xs={12} sx={{ padding: "20px 0 0 0", marginBottom: "25px" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
            }}
          >
            Calendar
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Calendar />
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#E2F6FC" }}>
                  <TableCell sx={{ fontWeight: "bold" }}>Property</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Guest
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Check-In/Out
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Gross Booking
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Price
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Days
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" scope="row">
                      {row.property}
                    </TableCell>
                    <TableCell align="right" scope="row">
                      {row.guest}
                    </TableCell>
                    <TableCell align="right">{row.checkInOut}</TableCell>
                    <TableCell align="right">{row.bookingStatus}</TableCell>
                    <TableCell align="right">{row.price}</TableCell>
                    <TableCell align="right">{row.days}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CalendarPage;
