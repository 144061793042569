import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginInfo } from "../../types/formsTypes";
import { useFormik } from "formik";
import { loginUser } from "../../store/thunks/authenticationThunk";
import Footer from "../../components/commonComponents/footer/footer";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import * as Yup from "yup";
import {
  Grid,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Backdrop,
  CircularProgress,
  Snackbar,
  styled,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import GoogleLogin from "react-google-login";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import { reset, setPreviousLocation } from "../../store";
import { LVLpmThemeButtonOther } from "../../utilities/constants";
import { displayConsoleError } from "../../utilities/helperFunctions";
// import FacebookLogin from "react-facebook-login";

const Login = () => {
  const location = useLocation();
  const { loginLoading, error, loggedIn, previousLocation, userInfo } =
    useAppSelector((state: any) => state.authentication);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  useEffect(() => {
    if (loggedIn) {
      if (previousLocation && previousLocation !== "") {
        dispatch(setPreviousLocation(location.pathname));
        navigate(previousLocation);
        dispatch(setPreviousLocation(""));
      } else {
        navigate("/dashboard");
      }
    } else {
      if (previousLocation && previousLocation !== "") {
        if (previousLocation?.includes("resetpassword")) {
          setOpenSnackbar(true);
          dispatch(setPreviousLocation(""));
        }
      }
    }
    dispatch(reset());
  }, [navigate, loggedIn]);

  const validateLogin = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is Required"),
    password: Yup.string()
      .min(8, "Too Short!")
      .max(50, "Too Long!")
      .required("Password is Required"),
  });

  let loginInitialValues: LoginInfo = {
    email: "",
    password: "",
  };

  const loginForm = useFormik({
    initialValues: loginInitialValues,
    validationSchema: validateLogin,
    onSubmit: (values) => {
      setLoginError(false);
      setLoginErrorMessage("");
      const loginPayload = {
        email: values.email.toLowerCase(),
        password: values.password,
      };

      dispatch(loginUser(loginPayload))
        .then((result: any) => {
          if (result?.error) {
            setLoginError(true);
            setLoginErrorMessage(
              "Invalide email or password, please try again"
            );
          }
        })
        .catch((error) => {
          displayConsoleError("resetPasswordLinkVerification", error);
        });
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const responseGoogle = (response: any) => {
    console.log(response);
  };

  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
          paddingTop: "5%",
        }}
      >
        <Spinner loading={loginLoading} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={5000}
          open={openSnackbar}
          message={"Password Has been reset successfully"}
          key={"topcenter"}
          onClose={() => setOpenSnackbar(false)}
        />
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            zIndex: 2,
            justifyContent: "center",
          }}
        >
          <Paper
            sx={{
              zIndex: 2,
              width: "100%",
              maxWidth: "600px",
              p: 3,
            }}
            elevation={3}
          >
            <form onSubmit={loginForm.handleSubmit}>
              <Grid container sx={{ gap: 1.5 }}>
                {/* <Grid item xs={12} sx={{}}>
                  <Button variant="contained" sx={{ width: "100%" }}>
                    Sign in with Google
                  </Button>
                  {false && (
                    <GoogleLogin
                      clientId="YOUR_CLIENT_ID"
                      buttonText="Sign in with Google"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%", backgroundColor: "#4267B2" }}
                  >
                    Sign in with Facebook
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%", backgroundColor: "#000000" }}
                  >
                    Sign in with apple
                  </Button>
                </Grid> */}
                {/* <Divider sx={{ width: "100%", padding: "20px" }}>Or</Divider> */}
                <Grid item xs={12} sx={{}}>
                  <Typography>Email</Typography>
                  <TextField
                    sx={{}}
                    placeholder="Email"
                    name="email"
                    inputProps={{}}
                    fullWidth
                    value={loginForm.values.email}
                    error={
                      loginForm.touched.email && Boolean(loginForm.errors.email)
                    }
                    helperText={loginForm.errors.email}
                    onChange={loginForm.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <Typography>Password</Typography>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Password"
                    name="password"
                    fullWidth
                    value={loginForm.values.password}
                    error={
                      loginForm.touched.password &&
                      Boolean(loginForm.errors.password)
                    }
                    onChange={loginForm.handleChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid item sx={{}}>
                    Remember me
                  </Grid>
                  <Grid item sx={{}}>
                    <Button onClick={() => navigate("/forgotpassword")}>
                      Forgot password
                    </Button>
                  </Grid>
                </Grid>
                {error && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <Typography>{error?.data?.message}</Typography>
                  </Grid>
                )}
                {loginError && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <Typography>{loginErrorMessage}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sx={{}}>
                  <LVLpmThemeButtonOther
                    variant="contained"
                    onClick={() => loginForm.submitForm}
                    type="submit"
                  >
                    Sign In
                  </LVLpmThemeButtonOther>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Don't Have an account?{" "}
                  <Button onClick={() => navigate("/register")}>Sign Up</Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
