import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import "react-chat-elements/dist/main.css";
import "./MessagePageStyles.css";
import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import MessageListComponent from "./MessageListComponent";
import MessageBoxComponent from "./MessageBoxComponent";
import MessageInputComponent from "./MessageInputComponent";
import MessageProfileComponent from "./MessageProfileComponent";
import { getUserMessageList } from "../../store/thunks/messagesThunk";
import { LVLpmThemeButton } from "../../utilities/constants";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import dayjs from "dayjs";
import {
  setCreatedUserMessageList,
  setCurrentUserMessageList,
} from "../../store";

const MessagePage = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [messagesList, setMessagesList] = useState<any[]>([]);

  const { loggedIn, userInfo } = useAppSelector(
    (state: any) => state.authentication
  );
  const {
    userMessagesList,
    getUserMessageListLoading,
    createdUserMessageList,
    currentUserMessageList,
  } = useAppSelector((state: any) => state.messages);
  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    } else {
      dispatch(getUserMessageList({ user_id: userInfo.user_id }));
    }
  }, [navigate, loggedIn]);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const chatWithSupport = () => {};

  const initialMessageListSetup = () => {
    let tempMessageList: any[] = [];

    userMessagesList?.forEach((element: any) => {
      if (createdUserMessageList) {
        if (
          createdUserMessageList.message_list_id === element.message_list_id
        ) {
          dispatch(setCurrentUserMessageList(element));
          dispatch(setCreatedUserMessageList(null));
        }
      }

      let tempTitle = "";
      if (element.sender_id === userInfo.user_id) {
        tempTitle = `${element.reciever_first_name} ${element.reciever_last_name}`;
      } else if (element.reciever_id === userInfo.user_id) {
        tempTitle = `${element.sender_first_name} ${element.sender_last_name}`;
      }

      tempMessageList.push({
        id: element.message_list_id,
        avatar: "https://avatars.githubusercontent.com/u/80540635?v=4",
        alt: tempTitle,
        title: tempTitle,
        subtitle: element.message_list_subtitle,
        date: dayjs(element.message_list_start_date),
        unread: 0,
        info: element,
      });
    });

    setMessagesList(tempMessageList);
  };

  useEffect(() => {
    initialMessageListSetup();
  }, [userMessagesList]);

  return (
    <Box
      sx={{
        padding: "0 20px 0 80px",
        width: "100%",
      }}
    >
      <AccountDrawer handleDrawer={handleDrawer} open={open} />
      <Spinner loading={getUserMessageListLoading} />
      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid sx={{ padding: "20px 0 0 0" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
            }}
          >
            Messages
          </Typography>
        </Grid>
        <Grid sx={{ padding: "20px 0 0 0" }}>
          <LVLpmThemeButton
            variant="contained"
            onClick={() => chatWithSupport()}
          >
            Chat With Support
          </LVLpmThemeButton>
        </Grid>
      </Grid>
      {userMessagesList && userMessagesList.length > 0 && (
        <Grid
          container
          sx={{ height: `calc(100vh - 126px)`, position: "relative" }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              maxHeight: "650px",
              overflow: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "#00B9D1 white",
              backgroundColor: "transparent",
            }}
          >
            <MessageListComponent messageList={messagesList} />
          </Grid>
          {currentUserMessageList && (
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  minHeight: "650px",
                  maxHeight: "650px",
                  border: "1px solid #dbdbdb",
                  borderTop: "none",
                  overflow: "auto",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#00B9D1 white",
                  backgroundColor: "#fff",
                }}
              >
                <MessageBoxComponent />
              </Box>
              <MessageInputComponent />
            </Grid>
          )}
          {currentUserMessageList && (
            <Grid
              item
              xs={12}
              md={3}
              sx={{ backgroundColor: "#fff", maxHeight: "650px" }}
            >
              <MessageProfileComponent />
            </Grid>
          )}
          <Grid
            sx={{
              width: "100vw",
              marginLeft: "-25px",
              height: { xs: "650px" },
              backgroundImage:
                "linear-gradient( white, #D5EEF5, #D5EEF5, #D5EEF5, #D5EEF5, #D5EEF5)",
              position: "absolute",
              zIndex: "-1",
              bottom: "0",
            }}
          ></Grid>
        </Grid>
      )}
    </Box>
  );
};

export default MessagePage;
