import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import ImgPlaceholder from "../../assets/home-3.jpeg";
import PropertiesCard from "../../components/reusableComponents/propertiesCard/propertiesCard";
import HorizontalLinearStepper from "./HorizontalLinearStepper";
import {
  getAllCountries,
  getAllPropertyListingType,
  getAllPropertyRoomType,
  getOwnerProperties,
} from "../../store/thunks/propertyThunk";
import { getAllPropertyTypes } from "../../store/thunks/listingsThunk";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { LVLpmThemeButton } from "../../utilities/constants";
import { LoadScript, useJsApiLoader } from "@react-google-maps/api";

const PropertiesPage = () => {
  const navigate = useNavigate();
  const { userInfo, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );
  const { ownerProperties } = useAppSelector((state: any) => state.property);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [newPropertyOpen, setNewPropertyOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBr_kgPXvcnTQ1w4DP_UvSy-fVUa1QuT8s",
    libraries: ["places"],
  });

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    } else {
      if (userInfo?.account_id > 1) {
        dispatch(getOwnerProperties({ user_id: userInfo?.user_id }));
        dispatch(getAllPropertyTypes({ value: "" }));
        dispatch(getAllPropertyListingType({ value: "" }));
        dispatch(getAllPropertyRoomType({ value: "" }));
        dispatch(getAllCountries({ value: "" }));
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNewPropertyOpen = () => {
    setNewPropertyOpen(!newPropertyOpen);
  };

  const handleClose = () => {
    setNewPropertyOpen(false);
  };

  const matchesX = useMediaQuery("(min-width:900px)");
  const matchesY = useMediaQuery("(min-height:900px)");

  return (
    <Box sx={{ margin: "0 15px 0 80px" }}>
      <AccountDrawer handleDrawer={handleDrawer} open={drawerOpen} />

      <Grid container sx={{ marginBottom: "35px" }}>
        <Grid
          item
          xs={12}
          style={{
            padding: "15px 0 0 0",
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
            }}
          >
            My Properties
          </Typography>
          <LVLpmThemeButton
            variant="contained"
            color="success"
            onClick={handleNewPropertyOpen}
          >
            Create New
          </LVLpmThemeButton>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "40px" }}>
        {ownerProperties?.map((property: any) => (
          <PropertiesCard
            key={property.property_id}
            img={property.property_images_url}
            title={property.property_name}
            address={`${property.street_1} ${property.street_2}, ${property.city_name},${property.state_name}, ${property.country_name}`}
            stays={!property.stays ? 0 : property?.stays}
            rating={property?.rating}
            to={property?.property_id}
          />
        ))}
      </Grid>
      <Modal
        open={newPropertyOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "75%",
            maxHeight: "900px",
            overflowY: "scroll",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 30,
            p: 0,
            borderRadius: "15px",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              sx={{
                fontWeight: "bold",
                padding: "10px 0 10px 10px",
                backgroundColor: "#00B9D1",
                color: "#fff",
                width: "100%",
              }}
            >
              Create New Listing!
            </Typography>

            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#fff",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Box sx={{ padding: "30px" }}>
            {isLoaded && (
              <HorizontalLinearStepper handleOnClose={handleClose} />
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PropertiesPage;
