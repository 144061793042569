import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import HorizontalCard from "../../components/reusableComponents/horizontalCard/horizontalCard";
import DashboardFooter from "../../components/reusableComponents/dashboardFooter/dashboardFooter";
// placeholder img / bring in iamge from aws in the future
import RenterCard from "../../components/reusableComponents/renterCard/renterCard";
import {
  getOwnerBooking,
  getRenterBooking,
} from "../../store/thunks/bookingsThunk";
import dayjs from "dayjs";
import { getPropertiesListings } from "../../store/thunks/listingsThunk";
import { setPreviousLocation } from "../../store";
import { getSupportUssers } from "../../store/thunks/authenticationThunk";

const Account = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { previousLocation, loggedIn, userInfo } = useAppSelector(
    (state: any) => state.authentication
  );
  const { renterBookings, ownerBookings } = useAppSelector(
    (state: any) => state.bookings
  );

  useEffect(() => {
    if (loggedIn) {
      dispatch(getSupportUssers({}));
      if (previousLocation && previousLocation !== "") {
        navigate(previousLocation);
        dispatch(setPreviousLocation(""));
      }
    } else {
      navigate("/login");
    }

    if (userInfo === null && loggedIn === false) {
      navigate("/login");
    }
  }, [navigate, loggedIn]);

  useEffect(() => {
    dispatch(getPropertiesListings({ value: "" }));
    if (userInfo?.account_id === 1) {
      dispatch(getRenterBooking({ user_id: userInfo?.user_id }));
    } else {
      dispatch(getOwnerBooking({ owner_id: userInfo?.user_id }));
      dispatch(getRenterBooking({ user_id: userInfo?.user_id }));
    }
  }, []);
  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleMyBookings = () => {
    navigate("/reservations");
  };
  const handleMyPropertiesBookings = () => {
    navigate("/propertyReservations");
  };

  return (
    <Box
      sx={{
        padding: "0 20px 0 80px",
        width: "100%",
      }}
    >
      <AccountDrawer handleDrawer={handleDrawer} open={open} />
      <Grid container>
        <Grid item xs={12} sx={{ padding: "20px 0 0 0" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              marginBottom: "30px",
            }}
          >
            Good Morning,{" "}
            {userInfo?.first_name.charAt(0).toUpperCase() +
              userInfo?.first_name.slice(1)}
            !
          </Typography>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "2%",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                display: "flex",
                fontWeight: "bold",
                textTransform: "capitalize",
                marginBottom: "25px",
              }}
            >
              {renterBookings.length > 0
                ? "Upcoming Reservations"
                : "No Upcoming Reservations"}
            </Typography>
            {renterBookings.length > 2 && (
              <Button
                variant="contained"
                size={"small"}
                onClick={() => handleMyBookings()}
              >
                Bookings ({renterBookings.length})
              </Button>
            )}
          </Grid>
          <Box sx={{ marginBottom: "1%" }}>
            <Grid container spacing={2}>
              {renterBookings?.slice(0, 2)?.map((element: any, index: any) => {
                return (
                  <HorizontalCard
                    bookingsLength={renterBookings.length}
                    color={"#00DCDC"}
                    bookingDetails={element}
                    key={index}
                  />
                );
              })}
            </Grid>
          </Box>
          {Number(userInfo?.account_id) > 1 && (
            <Box sx={{ marginBottom: "35px" }}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1%",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    marginBottom: "25px",
                  }}
                >
                  {ownerBookings?.length > 0
                    ? "Upcoming Properties Reservations"
                    : "No Upcoming Properties Reservations"}
                </Typography>
                {ownerBookings?.length > 4 && (
                  <Button
                    variant="contained"
                    size={"small"}
                    onClick={() => handleMyPropertiesBookings()}
                  >
                    Properties Bookings ({ownerBookings.length})
                  </Button>
                )}
              </Grid>
              <Grid container spacing={2}>
                {ownerBookings
                  ?.slice(0, 4)
                  ?.map((renter: any, index: number) => (
                    <RenterCard
                      key={index}
                      color="#00DCDC"
                      bookingDetails={renter}
                    />
                  ))}
              </Grid>
            </Box>
          )}
        </Grid>
        {ownerBookings?.length === 0 ? (
          <Box sx={{ height: " 440px" }}></Box>
        ) : (
          ""
        )}
      </Grid>
      <DashboardFooter />
    </Box>
  );
};

export default Account;
