import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { setSelectedProperty } from "../../../store/index";
import { getPropertySpecifications } from "../../../apis/listingsApis";
import { dbKeyConverterToLabel } from "../../../utilities/utils";
import { PropertySpecifications } from "../../../types/propertiesTypes";
import dayjs from "dayjs";
import styled from "styled-components";
import RatingStar from "../ratingStar/ratingStar";
import { LVLpmThemeButton } from "../../../utilities/constants";

interface SearchCardProps {
  propertyDetails: any;
}

const ImgGrid = styled.div`
  & {
    height: 100%;
  }
  div:nth-child(1) {
    border-radius: 15px;
    height: 100%;
  }
`;

const SearchCard = (prop: SearchCardProps) => {
  const { propertyDetails } = prop;
  const { currentDestination } = useAppSelector((state: any) => state.listings);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [propertySpecifications, setPropertySpecifications] =
    useState<PropertySpecifications>({});
  const [propertySpecificationsView, setPropertySpecificationsView] =
    useState("");

  const [pricePerDay, setPricePerDay] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    getPropertySpecificationsView();
  }, []);

  useEffect(() => {
    setPricingDetails();
  }, [currentDestination]);

  const setPricingDetails = () => {
    let checkIn =
      typeof currentDestination?.checkIn === "string"
        ? dayjs(new Date(currentDestination?.checkIn))
        : currentDestination?.checkIn;
    let checkOut =
      typeof currentDestination?.checkOut === "string"
        ? dayjs(new Date(currentDestination?.checkOut))
        : currentDestination?.checkOut;
    const number_of_days =
      currentDestination?.checkOut && currentDestination?.checkIn
        ? checkOut > checkIn
          ? Math.ceil((checkOut - checkIn) / (1000 * 60 * 60 * 24))
          : 0
        : 0;
    const temp_price_per_day = propertyDetails.price_per_day
      ? Math.ceil(propertyDetails.price_per_day * 1.033)
      : 0;
    const price_nights_total = propertyDetails.price_per_day
      ? Math.ceil(temp_price_per_day * 1.078 * number_of_days)
      : 0;
    const temp_total_price = price_nights_total
      ? price_nights_total + Number(propertyDetails.cleaning_fee)
      : 0;
    const price_with_taxes = propertyDetails.price_per_day
      ? Math.ceil(temp_total_price * 1.078)
      : 0;

    setPricePerDay(Math.round(temp_price_per_day * 100) / 100);
    setTotalPrice(Math.round(price_with_taxes * 100) / 100);
  };

  const handleSelectListing = (propertyDetails: any) => {
    let tempSelectedPorpertyDetails = {
      ...propertyDetails,
      propertySpecifications: propertySpecifications,
    };
    dispatch(setSelectedProperty(tempSelectedPorpertyDetails));
    navigate("/listings/" + propertyDetails.property_id);
  };

  const getPropertySpecificationsView = async () => {
    const tempSpec = [];
    const propertySpecs = await getPropertySpecifications(
      propertyDetails.property_id
    );

    if (propertySpecs?.property_specifications?.length > 0) {
      setPropertySpecifications(propertySpecs.property_specifications[0]);
      let specs = propertySpecs.property_specifications[0];
      let specsKeys = Object.keys(specs).sort(() => 0.5 - Math.random());

      for (const key of specsKeys) {
        if (specs[key] === true) {
          tempSpec.push(dbKeyConverterToLabel(key));
        }
        if (tempSpec.length === 3) {
          break;
        }
      }
      setPropertySpecificationsView(tempSpec.join(" * "));
    }
  };
  // console.log(propertyDetails);
  return (
    <Box
      sx={{
        padding: "2%",
        border: "1px solid #e6e6e6",
        marginBottom: "15px",
        borderRadius: "15px",
        backgroundColor: "#fff",
      }}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <ImgGrid>
              <AwesomeSlider bullets={false} organicArrows={false}>
                {propertyDetails?.property_images_url
                  .split(",")
                  .map((image_url: string, index: number) => {
                    return (
                      <Box key={index}>
                        <img
                          src={image_url}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </Box>
                    );
                  })}
              </AwesomeSlider>
            </ImgGrid>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12} sx={{ marginTop: "auto" }}>
            <Box sx={{ width: "100%", paddingRight: "20px" }}>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {propertyDetails.property_name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", color: "#00B9D1" }}
                >
                  {propertyDetails.city}, {propertyDetails.state}
                </Typography>
              </Box>
              <hr style={{ border: "1px solid #e6e6e6", width: "100%" }} />
            </Box>
            <Box sx={{ marginBottom: "25px" }}>
              <Typography variant="body2">
                {propertyDetails.guests} guests | {propertyDetails.bedrooms}{" "}
                bedrooms | {propertyDetails.bathrooms} bathrooms
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <RatingStar
                    rating={parseInt(propertyDetails.review)}
                    color="gold"
                  />
                  <Typography variant="body1" sx={{ margin: "0 10px" }}>
                    {propertyDetails.review}
                  </Typography>
                  <Typography variant="body2">(325 Reviews)</Typography>
                </Box>
                <Box sx={{ marginTop: "12px" }}>
                  <LVLpmThemeButton
                    variant="contained"
                    onClick={() => handleSelectListing(propertyDetails)}
                  >
                    See Availability
                  </LVLpmThemeButton>
                </Box>
              </Box>
              <Box sx={{ marginTop: "auto" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  ${propertyDetails.price_per_day}/Night
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SearchCard;
