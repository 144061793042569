import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";

import AccountDrawer from "../../../../components/commonComponents/accountDrawer/accountDrawer";
import { useAppSelector, useAppDispatch } from "../../../../hooks/hooks";
import { getUserAddress } from "../../../../store/thunks/addressThunk";
import SettingInfoCard from "../../../../components/reusableComponents/settingInfoCard/settingInfoCard";
import AddressAddModal from "./AddressAddModal";
import AddressEditModal from "./AddressEditModal";
import ImgPlaceholder from "../../../../assets/home-2.jpeg";
import { LVLpmThemeButton } from "../../../../utilities/constants";

const AddressInfoPage = () => {
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);
  const dispatch = useAppDispatch();
  const { user_id } = userInfo;
  const [address, setAddress] = useState(true);
  const [addressInfo, setAddressInfo] = useState({});
  const [open, setOpen] = useState(false);
  const { street_1, street_2, city, state, zip_code, country }: any =
    addressInfo;

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserAddress({ user_id: user_id })).then((res) => {
        if (Object.keys(res.payload).length === 0) {
          setAddress(false);
        } else {
          setAddress(true);
          setAddressInfo(res.payload.address_info);
        }
      });
    }
  }, []);
  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <>
        {address ? (
          <>
            <Grid
              component="div"
              container
              sx={{
                width: "100%",
                padding: "15px 15px 15px 0 ",
              }}
            >
              <SettingInfoCard
                title="Address 1"
                data={street_1 ? street_1 : "Street"}
                type="addressInfo"
                xs={12}
                md={4}
              />
              <SettingInfoCard
                title="Address 2"
                data={street_2 ? street_2 : "Apt/Unit"}
                type="addressInfo"
                xs={12}
                md={4}
              />
              <SettingInfoCard
                title="City"
                data={city ? city : "City"}
                type="addressInfo"
                xs={12}
                md={4}
              />
              <SettingInfoCard
                title="State"
                data={state}
                type="addressInfo"
                xs={12}
                md={4}
              />
              <SettingInfoCard
                title="Zip"
                data={zip_code}
                type="addressInfo"
                xs={12}
                md={4}
              />
              <SettingInfoCard
                title="Country"
                data={country}
                type="addressInfo"
                xs={12}
                md={4}
              />
            </Grid>
            <LVLpmThemeButton
              variant="contained"
              color="success"
              onClick={handleOpenEdit}
              sx={{ marginBottom: "20px" }}
            >
              Edit Address
            </LVLpmThemeButton>
          </>
        ) : (
          <Grid item md={8} sm={12}>
            <LVLpmThemeButton
              variant="contained"
              color="success"
              onClick={handleOpenAdd}
              sx={{ marginBottom: "20px" }}
            >
              Add Address
            </LVLpmThemeButton>
          </Grid>
        )}
      </>
      <AddressAddModal
        open={openAdd}
        handleClose={handleCloseAdd}
        user_id={user_id}
      />
      <AddressEditModal
        open={openEdit}
        handleClose={handleCloseEdit}
        addressInfo={addressInfo}
      />
    </>
  );
};

export default AddressInfoPage;
