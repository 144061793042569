import { Box, Grid, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import TableTemplate from "../../../components/reusableComponents/tableTemplate/tableTemplate";
const AddressManagement = () => {
  const { userInfo } = useAppSelector((state: any) => state.authentication);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const {
    data: { data = [] } = {}, //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["table-data"],
    queryFn: async () => {
      const response: any = [];
      //   const response: any = await dispatch(
      //     getRenterCompletedBooking({ user_id: userInfo.user_id })
      //   )
      //     .then((result: any) => {
      //       if (result?.payload?.renter_bookings) {
      //         console.log("result.payload", result.payload);
      //         setRenterCompletedBookings(result?.payload?.renter_bookings);
      //       }
      //     })
      //     .catch((error) => {});
      return response ? response : {};
    },
    placeholderData: keepPreviousData,
  });

  const addressColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "property_name",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Property Name",
      },
      {
        accessorFn: (row: any) =>
          dayjs(row.booking_create_date).format("MMM DD, YYYY"),
        id: "booking_create_date",
        header: "Booking Date",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorFn: (row: any) => dayjs(row.check_in).format("MMM DD, YYYY"),
        id: "check_in",
        header: "Check In",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorFn: (row: any) => dayjs(row.check_out).format("MMM DD, YYYY"),
        id: "check_out",
        header: "Check Out",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },

      {
        accessorKey: "total_price",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Price",
      },
      {
        accessorKey: "status_label",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Status",
      },

      // {
      //   id: "Full Name", //id used to define `group` column
      //   header: "",
      //   columns: [
      //     {
      //       accessorFn: (row) => `${row.firstName} ${row.lastName}`, //accessorFn used to join multiple data into a single cell
      //       id: "name", //id is still required when using accessorFn instead of accessorKey
      //       header: "Name",
      //       size: 250,
      //       Cell: ({ renderedCellValue, row }) => (
      //         <Box
      //           sx={{
      //             display: "flex",
      //             alignItems: "center",
      //             gap: "1rem",
      //           }}
      //         >
      //           <span>{renderedCellValue}</span>
      //         </Box>
      //       ),
      //     },
      //     {
      //       accessorKey: "email", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      //       enableClickToCopy: true,
      //       filterVariant: "autocomplete",
      //       header: "Email",
      //       size: 300,
      //     },
      //   ],
      // },
      // {
      //   id: "id",
      //   header: "",
      //   columns: [
      //     {
      //       accessorKey: "salary",
      //       // filterVariant: 'range', //if not using filter modes feature, use this instead of filterFn
      //       filterFn: "between",
      //       header: "Salary",
      //       size: 200,
      //       //custom conditional format and styling
      //       Cell: ({ cell }) => (
      //         <Box
      //           component="span"
      //           sx={(theme) => ({
      //             backgroundColor:
      //               cell.getValue<number>() < 50_000
      //                 ? theme.palette.error.dark
      //                 : cell.getValue<number>() >= 50_000 &&
      //                   cell.getValue<number>() < 75_000
      //                 ? theme.palette.warning.dark
      //                 : theme.palette.success.dark,
      //             borderRadius: "0.25rem",
      //             color: "#fff",
      //             maxWidth: "9ch",
      //             p: "0.25rem",
      //           })}
      //         >
      //           {cell.getValue<number>()?.toLocaleString?.("en-US", {
      //             style: "currency",
      //             currency: "USD",
      //             minimumFractionDigits: 0,
      //             maximumFractionDigits: 0,
      //           })}
      //         </Box>
      //       ),
      //     },
      //     {
      //       accessorKey: "jobTitle", //hey a simple column for once
      //       header: "Job Title",
      //       size: 350,
      //     },
      //     {
      //       accessorFn: (row) => new Date(row.startDate), //convert to Date for sorting and filtering
      //       id: "startDate",
      //       header: "Start Date",
      //       filterVariant: "date",
      //       filterFn: "lessThan",
      //       sortingFn: "datetime",
      //       Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
      //       Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      //       muiFilterTextFieldProps: {
      //         sx: {
      //           minWidth: "250px",
      //         },
      //       },
      //     },
      //   ],
      // },
    ],
    []
  );

  const addressTable = useMaterialReactTable({
    columns: addressColumns,
    data: [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    state: {
      isLoading: false, //cell skeletons and loading overlay
      // showProgressBars: getUserLoading, //progress bars while refetching
      // isSaving: getUserLoading, //progress bars and save button spinners
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
    },
    // renderDetailPanel: ({ row }) => (
    //   <Box
    //     sx={{
    //       alignItems: "center",
    //       display: "flex",
    //       justifyContent: "space-around",
    //       left: "30px",
    //       maxWidth: "1000px",
    //       position: "sticky",
    //       width: "100%",
    //     }}
    //   >
    //     <Box sx={{ textAlign: "center" }}>
    //       <Typography variant="h4">Signature Catch Phrase:</Typography>
    //       <Typography variant="h1">
    //         &quot;{row.original.signatureCatchPhrase}&quot;
    //       </Typography>
    //     </Box>
    //   </Box>
    // ),
    renderRowActionMenuItems: ({ closeMenu, row }) => [],
    // renderTopToolbar: ({ table }) => {
    //   const handleDeactivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert("deactivating " + row.getValue("name"));
    //     });
    //   };

    //   const handleActivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert("activating " + row.getValue("name"));
    //     });
    //   };

    //   const handleContact = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert("contact " + row.getValue("name"));
    //     });
    //   };

    //   return (
    //     <Box
    //       sx={(theme) => ({
    //         backgroundColor: lighten(theme.palette.background.default, 0.05),
    //         display: "flex",
    //         gap: "0.5rem",
    //         p: "8px",
    //         justifyContent: "space-between",
    //       })}
    //     >
    //       <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
    //         {/* import MRT sub-components */}
    //         <MRT_GlobalFilterTextField table={table} />
    //         <MRT_ToggleFiltersButton table={table} />
    //       </Box>
    //       <Box>
    //         <Box sx={{ display: "flex", gap: "0.5rem" }}>
    //           <Button
    //             color="error"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleDeactivate}
    //             variant="contained"
    //           >
    //             Deactivate
    //           </Button>
    //           <Button
    //             color="success"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleActivate}
    //             variant="contained"
    //           >
    //             Activate
    //           </Button>
    //           <Button
    //             color="info"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleContact}
    //             variant="contained"
    //           >
    //             Contact
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Box>
    //   );
    // },
  });
  return (
    <Grid container>
      <Grid container item xs={12} sx={{ padding: "20px 10px 20px 10px" }}>
        <TableTemplate tableData={addressTable} />
      </Grid>
    </Grid>
  );
};
export default AddressManagement;
