import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utilities/authMidleware";
import { backendURL, config } from "../../utilities/utils";
import { displayConsoleError } from "../../utilities/helperFunctions";

const propertyRoute = "/api/v1/property";

const getAllProperties = createAsyncThunk(
  "property/allProperties",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${propertyRoute}/getAllProperties`,
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllProperties", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllPropertyTypes = createAsyncThunk(
  "property/getAllPropertyTypes",
  async ({ value }: { value: any }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${propertyRoute}/getAllPropertyTypes`,
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllPropertyTypes", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllCountries = createAsyncThunk(
  "property/getAllCountries",
  async ({ value }: { value: any }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${propertyRoute}/getAllCountries`,
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllCountries", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllStates = createAsyncThunk(
  "property/getAllStates",
  async ({ value }: { value: any }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/getAllStates`,
        value,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllStates", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllCities = createAsyncThunk(
  "property/getAllCities",
  async ({ value }: { value: any }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/getAllCities`,
        value,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllCities", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPropertyType = createAsyncThunk(
  "property/propertyType",
  async (
    { property_type_id }: { property_type_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/property/getPropertyType`,
        {
          property_type_id,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getPropertyType", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllPropertyRoomType = createAsyncThunk(
  "property/getAllPropertyRoomType",
  async ({ value }: { value: any }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${propertyRoute}/getAllPropertyRoomType`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllPropertyRoomType", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllPropertyListingType = createAsyncThunk(
  "property/getAllPropertyListingType",
  async ({ value }: { value: any }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${propertyRoute}/getAllPropertyListingType`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllPropertyListingType", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getOwnerProperties = createAsyncThunk(
  "property/getOwnerProperties",
  async ({ user_id }: { user_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/getOwnerProperties`,
        { user_id: user_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getOwnerProperties", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const addOwnerProperty = createAsyncThunk(
  "property/addOwnerProperty",
  async ({ values }: { values: any }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/addOwnerProperty`,
        values,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("addOwnerProperty", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const uploadPropertyImages = createAsyncThunk(
  "property/uploadPropertyImages",
  async (
    { images, property_id }: { images: any; property_id: string },
    { rejectWithValue }
  ) => {
    const tempHeaders = tokenInterceptor();
    const filelist = Array.from(images);
    const formData = new FormData();
    formData.append("property_id", property_id);
    filelist.forEach((element: any, index: any) => {
      formData.append(`image_${index}`, element);
    });

    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/uploadPropertyImages`,
        formData,
        {
          ...tempHeaders,
          headers: {
            ...tempHeaders.headers,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("uploadPropertyImages", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPropertySpecification = createAsyncThunk(
  "property/propertySpecification",
  async ({ property_id }: { property_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/property/getPropertySpecifications`,
        {
          property_id,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getPropertySpecification", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPropertyImages = createAsyncThunk(
  "property/getPropertyImages",
  async (
    { property_id, count }: { property_id: string; count: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/property/getPropertyImages`,
        {
          property_id,
          count,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getPropertyImages", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getSpecificProperty = createAsyncThunk(
  "property/getSpecificProperty",
  async ({ property_id }: { property_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/property/getSpecificProperty`,
        {
          property_id,
        },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getSpecificProperty", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export {
  getOwnerProperties,
  addOwnerProperty,
  getAllPropertyTypes,
  getAllCountries,
  getAllStates,
  getAllCities,
  uploadPropertyImages,
  getAllPropertyRoomType,
  getAllPropertyListingType,
  getPropertyType,
  getAllProperties,
  getPropertySpecification,
  getPropertyImages,
  getSpecificProperty,
};
