import React from "react";
import { Form, Formik } from "formik";
import { Box, Button, Grid } from "@mui/material";

import { useAppDispatch } from "../../../../hooks/hooks";
import FormControl from "../../FormControl";
import { editUserAddress } from "../../../../store/thunks/addressThunk";
import { LVLpmThemeButton } from "../../../../utilities/constants";

interface AddressEditFormProps {
  addressInfo: any;
}

const AddressEditForm = (props: AddressEditFormProps) => {
  const dispatch = useAppDispatch();
  const { addressInfo } = props;

  const initialValues = {
    user_id: addressInfo.user_id,
    street_1: addressInfo.street_1,
    street_2: addressInfo.street_2,
    city: addressInfo.city,
    state: addressInfo.state,
    zip_code: addressInfo.zip_code,
    country: addressInfo.country,
  };
  const onSubmit = (values: any) => {
    dispatch(editUserAddress({ values })).then(() => {
      location.reload();
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <Form>
          <Grid container direction="row" spacing={2} sx={{ padding: "30px" }}>
            <Grid item xs={12} md={6}>
              <FormControl
                control="input"
                type="street_1"
                label="Address 1"
                name="street_1"
                values={initialValues.street_1}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                control="input"
                type="street_2"
                label="Address 2"
                name="street_2"
                values={initialValues.street_2}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                control="input"
                type="city"
                label="City"
                name="city"
                values={initialValues.city}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                control="input"
                type="state"
                label="State"
                name="state"
                values={initialValues.state}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                control="input"
                type="zip_code"
                label="Zipcode"
                name="zip_code"
                values={initialValues.zip_code}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                control="input"
                type="country"
                label="Country"
                name="country"
                values={initialValues.country}
              />
            </Grid>
          </Grid>

          <Box sx={{ padding: " 30px" }}>
            <LVLpmThemeButton type="submit" variant="contained" color="success">
              Edit Address
            </LVLpmThemeButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddressEditForm;
