import React from "react";
import { Form, Formik } from "formik";
import { Box, Button, Grid } from "@mui/material";

import { useAppDispatch } from "../../../../hooks/hooks";
import FormControl from "../../FormControl";
import { createUserAddress } from "../../../../store/thunks/addressThunk";
import { LVLpmThemeButton } from "../../../../utilities/constants";

interface AddressEditFormProps {
  user_id: number;
}

const AddressAddForm = (props: AddressEditFormProps) => {
  const dispatch = useAppDispatch();
  const { user_id } = props;

  const initialValues = {
    user_id: user_id,
    street_1: "",
    street_2: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
  };
  const onSubmit = (values: any) => {
    dispatch(createUserAddress({ values })).then(() => {
      alert("New Address Added.");
      location.reload();
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <Form>
          <Grid container direction="row" spacing={2} sx={{ padding: "30px" }}>
            <Grid item md={6} xs={12}>
              <FormControl
                control="input"
                type="street_1"
                label="Address 1"
                name="street_1"
                values={initialValues.street_1}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                control="input"
                type="street_2"
                label="Address 2"
                name="street_2"
                values={initialValues.street_2}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                control="input"
                type="city"
                label="City"
                name="city"
                values={initialValues.city}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                control="input"
                type="state"
                label="State"
                name="state"
                values={initialValues.state}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                control="input"
                type="zip_code"
                label="Zipcode"
                name="zip_code"
                values={initialValues.zip_code}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                control="input"
                type="country"
                label="Country"
                name="country"
                values={initialValues.country}
              />
            </Grid>
          </Grid>
          <Box sx={{ padding: " 30px" }}>
            <LVLpmThemeButton type="submit" variant="contained" color="success">
              Add Address
            </LVLpmThemeButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddressAddForm;
