import { Box, Container, Grid, Typography } from "@mui/material";

import LVLpmLogo from "../../assets/logo.jpg";
import Footer from "../../components/commonComponents/footer/footer";

const ContactPage = () => {
  return (
    <>
      <Box sx={{ backgroundColor: "#fff", paddingBottom: "30px" }}>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: "50px" }}>
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <img src={LVLpmLogo} style={{ width: "200px" }} />
              </Box>
              <Typography
                variant="body1"
                sx={{ margin: "35px 0 15px 0", fontWeight: "bold" }}
              >
                PRIVACY POLICY
              </Typography>
              <Box sx={{ display: "flex", marginBottom: "15px" }}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", marginRight: "10px" }}
                >
                  Effective Date: 09/14/2024
                  <br />
                  Last Updated: 09/14/2024
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>LVLpm LLC</span> (“LVLpm,”
                “we,” “us,” or “our”) respects your privacy and is committed to
                protecting the personal information that you provide to us. This
                Privacy Policy explains how we collect, use, disclose, and
                safeguard your information when you use our website, mobile
                application, and other services provided by LVLpm (collectively,
                “Services”). By accessing or using the Services, you consent to
                the collection, use, and disclosure of your information as
                described in this Privacy Policy.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY, YOU MUST
                IMMEDIATELY CEASE USING OUR SERVICES.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                1. Information We Collect
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                We collect various types of information, both personal and
                non-personal, to provide you with the best possible experience
                when you use our Services. This information includes:
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  1.1 Personal Information
                </span>
                <br />
                When you use the Services, you may be asked to provide personal
                information, which may include: <br />
                <span style={{ fontWeight: "bold" }}>
                  - Contact Information:
                </span>{" "}
                Name, email address, phone number, and mailing address. <br />
                <span style={{ fontWeight: "bold" }}>
                  - Account Information:
                </span>{" "}
                Username, password, and any other details necessary for account
                creation.
                <br />
                <span style={{ fontWeight: "bold" }}>
                  - Payment Information:
                </span>{" "}
                Credit card details or payment account information required to
                process bookings.
                <br />
                <span style={{ fontWeight: "bold" }}>
                  - Booking Information:
                </span>{" "}
                Details related to your reservations, including property
                preferences, check-in/check-out dates, and other relevant
                booking information.
                <br />
                <span style={{ fontWeight: "bold" }}>
                  - Identification Information:
                </span>{" "}
                Government-issued identification, such as driver’s license or
                passport, required for identity verification.
                <br />
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  1.2 Non-Personal Information
                </span>{" "}
                <br />
                We may also collect non-personal information about your use of
                the Services, which may include:
                <br />
                <span style={{ fontWeight: "bold" }}>
                  - Device Information:
                </span>{" "}
                IP address, browser type, operating system, and other
                device-specific information.
                <br />
                <span style={{ fontWeight: "bold" }}>- Usage Data:</span> Pages
                visited, time spent on the Services, and other usage details.
                <br />
                <span style={{ fontWeight: "bold" }}>
                  - Cookies and Tracking Technologies:
                </span>{" "}
                Information collected through cookies, web beacons, and similar
                technologies to track usage patterns and preferences.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  1.3 Sensitive Personal Data
                </span>
                <br />
                LVLpm does not intentionally collect any sensitive personal data
                (e.g., racial or ethnic origins, political opinions, religious
                beliefs, health information) unless explicitly provided by you
                for specific purposes (e.g., accommodation preferences). If we
                do collect sensitive personal data, it will be handled with
                appropriate security measures.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                2. How We Use Your Information
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                We use the information we collect for the following purposes:{" "}
                <br />
                <span style={{ fontWeight: "bold" }}>
                  - Providing Services:
                </span>
                <br />
                To facilitate property bookings, manage reservations, process
                payments, and offer customer support.
                <br />
                <span style={{ fontWeight: "bold" }}>
                  - Account Management:
                </span>{" "}
                To create, maintain, and update your LVLpm account.
                <br />
                <span style={{ fontWeight: "bold" }}>
                  - Personalization:
                </span>{" "}
                To personalize your experience, including offering tailored
                property suggestions and special offers.
                <br />
                <span style={{ fontWeight: "bold" }}>- Communication:</span> To
                send important updates, including booking confirmations, policy
                changes, and promotional offers.
                <br />
                <span style={{ fontWeight: "bold" }}>
                  - Legal Compliance:
                </span>{" "}
                To comply with applicable laws, regulations, and legal
                processes.
                <br />
                <span style={{ fontWeight: "bold" }}>
                  - Security and Fraud Prevention:
                </span>{" "}
                To ensure the security and integrity of our Services and prevent
                fraudulent activities.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                3. How We Share Your Information
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                LVLpm may share your personal information in the following
                circumstances:
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  3.1 With Hosts and Property Managers
                </span>
                <br />
                We may share information such as your name, contact details, and
                booking information with Hosts or property managers to
                facilitate your stay, including for communication, check-in
                arrangements, and the provision of services at the property.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  3.2 Service Providers
                </span>{" "}
                <br />
                We may share your information with third-party service providers
                that perform services on our behalf, including: <br />
                - Payment processors to handle transactions.
                <br />
                - Identity verification services.
                <br />
                - Hosting and technical support providers.
                <br />- Marketing and analytics partners to enhance our
                offerings.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  3.3 Business Transfers
                </span>{" "}
                <br />
                In the event of a merger, acquisition, financing, or sale of
                LVLpm’s assets, your information may be transferred as part of
                the transaction. We will notify you if such a transfer affects
                your personal data in any material way.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  3.4 Legal Obligations
                </span>{" "}
                <br />
                We may disclose your information if required to do so by law or
                in response to valid legal processes, such as a subpoena, court
                order, or government request.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  3.5 Protection of Rights
                </span>
                <br />
                We may disclose your information if we believe it is necessary
                to investigate, prevent, or take action regarding illegal
                activities, suspected fraud, violations of our Terms &
                Conditions, or as otherwise required by law.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  4. Your Privacy Rights
                </span>{" "}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  4.1 Access, Correction, and Deletion
                </span>{" "}
                You have the right to access, correct, or delete your personal
                information at any time. You can update your account information
                directly through your account settings or by contacting us at
                the email address provided below.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>4.2 Data Portability</span>
                <br />
                Where applicable, you may request a copy of the personal data we
                hold about you in a machine-readable format.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                4.3 California Residents (CCPA)
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                If you are a resident of California, you have the right to:{" "}
                <br />
                - Request information regarding the categories and specific
                pieces of personal data we have collected about you.
                <br />
                - Request the deletion of your personal data, subject to certain
                exceptions.
                <br />
                - Opt out of the sale of your personal information.
                <br />
                <br />
                To exercise these rights, please contact us at the email
                provided below.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  4.4 European Union Residents (GDPR)
                </span>{" "}
                <br />
                If you are a resident of the European Union, you have the
                following rights under the General Data Protection Regulation
                (GDPR): <br />
                - The right to access your personal data and request
                rectification or erasure. <br />
                - The right to restrict or object to the processing of your
                personal data. <br />
                - The right to data portability. <br />
                <br />
                To exercise your rights under GDPR, please contact us at the
                email provided below.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                5. Cookies and Tracking Technologies
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                LVLpm uses cookies and similar tracking technologies to enhance
                user experience, monitor usage, and provide personalized
                content. Cookies are small text files that are placed on your
                device when you visit our website. We use cookies to:
                <br />
                - Recognize you when you return to our site.
                <br /> - Understand your preferences and improve functionality.{" "}
                <br />- Serve relevant advertisements.
                <br /> <br />
                You may adjust your browser settings to refuse cookies, but
                doing so may affect your ability to use some features of the
                Services.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>6. Data Retention</span>{" "}
                <br />
                We will retain your personal information for as long as
                necessary to fulfill the purposes for which it was collected or
                as required by applicable law. If you close your LVLpm account,
                we will retain certain information only as required by law or
                for legitimate business purposes, such as preventing fraud,
                resolving disputes, or enforcing our Terms & Conditions.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                7. Security of Your Information
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                We take reasonable precautions to protect your personal
                information from unauthorized access, use, or disclosure. These
                measures include encryption, firewalls, and access controls.
                However, no method of transmission over the internet or
                electronic storage is entirely secure, and we cannot guarantee
                the absolute security of your information.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>7.2 Fees </span> <br />
                LVLpm may charge service fees to Hosts and Guests, including but
                not limited to booking fees, platform fees, cleaning fees, and
                upsell services. These fees will be disclosed at the time of
                booking and are non-refundable unless otherwise stated.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                8. International Transfers of Personal Data
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                LVLpm operates globally, and your personal information may be
                transferred to and processed in countries outside your own.
                These countries may have data protection laws that differ from
                those in your jurisdiction. In such cases, we will ensure that
                appropriate safeguards are in place to protect your personal
                information in compliance with applicable data protection laws.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                9. Third-Party Links
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                Our Services may contain links to third-party websites or
                services. This Privacy Policy does not apply to those
                third-party websites or services, and we encourage you to review
                the privacy policies of any third-party websites you visit.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                10. Children’s Privacy
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                Our Services are not intended for individuals under the age of
                18. We do not knowingly collect personal information from
                children under 18. If we discover that we have inadvertently
                collected personal information from a child under 18, we will
                take steps to delete such information as soon as possible.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                11. Changes to This Privacy Policy
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                LVLpm reserves the right to modify this Privacy Policy at any
                time. We will notify you of material changes by updating the
                "Effective Date" at the top of this Privacy Policy or by
                providing additional notice, such as via email or an in-service
                notification. Your continued use of the Services after any
                changes constitutes acceptance of the updated Privacy Policy.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                12. Contact Information
              </Typography>

              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                If you have any questions or concerns regarding this Privacy
                Policy or wish to exercise your privacy rights, please contact
                us at: <br />
                <span style={{ fontWeight: "bold" }}>- Email:</span>{" "}
                info@lvlpm.com <br />
                <span style={{ fontWeight: "bold" }}>- Phone:</span>{" "}
                213-597-9392 <br />
                <span style={{ fontWeight: "bold" }}>
                  - Mailing Address:
                </span>{" "}
                800 N King st, Wilmington DE 19801 <br /> <br />
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                By using our Services, you acknowledge that you have read,
                understood, and agreed to the terms of this Privacy Policy.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default ContactPage;
