import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/commonComponents/header/header";
import Footer from "./components/commonComponents/footer/footer";
import Layout from "./components/commonComponents/layout/layout";
import Home from "./pages/homePage/home";
import VacationRental from "./pages/vacationRentalPage/vacationRental";
import Company from "./pages/companyPage/company";
import Shopping from "./pages/shoopingPage/shopping";
import Listings from "./pages/listingsPage/listings";
import Login from "./pages/login/login";
import { CssBaseline, Grid, Toolbar, useScrollTrigger } from "@mui/material";
import Register from "./pages/register/register";
import EmailVerification from "./pages/emailVerificationPage/emailVerification";
import "./App.css";
import ListingDetails from "./pages/listingDetailPage/listingDetail";
import Account from "./pages/dashboardPage/dashboard";
import MessagePage from "./pages/messagePage/MessagePage";
import Calendar from "./pages/calendarPage/CalendarPage";
import Reports from "./pages/reports/reports";
import SettingsPage from "./pages/settingsPage/settingsPage";
import ResetPassword from "./pages/resetPasswordPage/resetPassword";
import ForgotPassword from "./pages/forgotPasswordPage/forgotPassword";
import PropertiesPage from "./pages/propertiesPage/propertiesPage";
import PropertyDetail from "./pages/propertiesPage/PropertyDetail";
import AdminPage from "./pages/admin/admin";
import ContactPage from "./pages/contactPage/ContactPage";
import PropertyReservations from "./pages/propertyReservationPage/propertyReservation";
import ReservationDetail from "./pages/reservationPage/reservationDetail/reservationDetail";
import Reservations from "./pages/reservationPage/reservation";
import HistoryPage from "./pages/historyPage/HistoryPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import CancellationPolicyPage from "./pages/cancellationPolicyPage/CancellationPolicyPage";
import TermsAndConditionsPage from "./pages/termsAndConditionsPage/TermsAndConditionsPage";
import PrivacyPolicyPage from "./pages/privayPolicyPage/PrivacyPolicyPage";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const renterCompletedBookingQueryClient = new QueryClient();

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid sx={{ width: "100%" }}>
        <Header />
        <Toolbar />
        <Grid>
          <Grid>
            <Routes>
              <Route path="/" element={<Layout children={""} />}>
                <Route index element={<Home />} />
                <Route
                  path="/management/vacationrental"
                  element={<VacationRental />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/company" element={<Company />} />
                <Route path="/shooping" element={<Shopping />} />
                <Route path="/listings" element={<Listings />} />
                <Route
                  path="/listings/:propertyId"
                  element={<ListingDetails />}
                />
                <Route
                  path="/emailVerification/:token"
                  element={<EmailVerification />}
                />
                <Route path="/dashboard" element={<Account />} />
                <Route path="/reservations" element={<Reservations />} />
                <Route
                  path="/reservations/:reservation_id"
                  element={<ReservationDetail />}
                />
                <Route
                  path="/propertyReservations"
                  element={<PropertyReservations />}
                />

                <Route path="/messages" element={<MessagePage />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/properties" element={<PropertiesPage />} />
                <Route
                  path={`/properties/:property`}
                  element={<PropertyDetail />}
                />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/management" element={<AdminPage />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route
                  path="/resetpassword/:token"
                  element={<ResetPassword />}
                />
                <Route path="/contact" element={<ContactPage />} />
                <Route
                  path="/cancellation-policy"
                  element={<CancellationPolicyPage />}
                />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditionsPage />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route
                  path="/history"
                  element={
                    <QueryClientProvider
                      client={renterCompletedBookingQueryClient}
                    >
                      <HistoryPage />
                    </QueryClientProvider>
                  }
                />
              </Route>
            </Routes>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default App;
