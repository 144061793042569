import { Box, Container, Grid, Typography } from "@mui/material";
import { useState } from "react";

import { useAppSelector } from "../../../../hooks/hooks";
import SettingInfoCard from "../../../../components/reusableComponents/settingInfoCard/settingInfoCard";

const SettingsSecurityPage = () => {
  const [open, setOpen] = useState(false);
  const { userInfo } = useAppSelector((state: any) => state.authentication);

  return (
    <>
      <Grid container spacing={2}>
        <SettingInfoCard
          title="Current Password"
          data={"asdf1234"}
          type="password"
          xs={12}
          md={6}
        />
      </Grid>
    </>
  );
};

export default SettingsSecurityPage;
