import { useState } from "react";
import { Field } from "formik";
import { FormGroup, FormLabel } from "@mui/material";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

interface FormInputProps {
  label: string;
  name: string;
}

const FormDate = (props: FormInputProps) => {
  const { label, name, ...rest } = props;

  return (
    <FormGroup sx={{ marginBottom: "20px" }}>
      <FormLabel
        htmlFor={name}
        sx={{ fontWeight: "bold", paddingBottom: "5px" }}
      >
        {label}
      </FormLabel>
      <Field name={name}>
        {({ form, field }: any) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <DatePicker
              id={name}
              {...field}
              {...rest}
              selected={value}
              onChange={(val: any) =>
                setFieldValue(name, moment(val).format("MM/DD/YYYY"))
              }
            />
          );
        }}
      </Field>
    </FormGroup>
  );
};

export default FormDate;
