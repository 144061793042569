import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utilities/authMidleware";
import { backendURL } from "../../utilities/utils";
import { displayConsoleError } from "../../utilities/helperFunctions";

const userRoute = "/api/v1/user";

const getUserSettings = createAsyncThunk(
  "user/getUserSettings",
  async ({ user_id }: { user_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${userRoute}/getUserSettings`,
        { user_id: user_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("error", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const updateUserSettings = createAsyncThunk(
  "user/updateUser",
  async ({ values }: { values: Object }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${userRoute}/updateUserSettings`,
        { values },
        tokenInterceptor()
      );
      alert("Update Successful.");
      return response.data;
    } catch (error: any) {
      displayConsoleError("error", error);
      alert("Something went wrong.");
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const trustSwiftyVerification = createAsyncThunk(
  "user/trustSwiftyVerification",
  async (
    { userVerificationPayload }: { userVerificationPayload: any },
    { rejectWithValue }
  ) => {
    const tempHeaders = tokenInterceptor();

    // const filelist = Array.from(images);
    const formData = new FormData();
    formData.append("address", userVerificationPayload?.address);
    formData.append("city", userVerificationPayload?.city);
    formData.append("country", userVerificationPayload?.country);
    formData.append("dob", userVerificationPayload?.dob);
    formData.append("email", userVerificationPayload?.email);
    formData.append("first_name", userVerificationPayload?.first_name);
    formData.append("last_name", userVerificationPayload?.last_name);
    formData.append("phone_number", userVerificationPayload?.phone_number);
    formData.append("state", userVerificationPayload?.state);
    formData.append("street_1", userVerificationPayload?.street_1);
    formData.append("street_2", userVerificationPayload?.street_2);
    const identification = Array.from(userVerificationPayload?.identification);

    identification.forEach((element: any, index: any) => {
      formData.append(`identification`, element);
    });
    const selfie = Array.from(userVerificationPayload?.identification);

    selfie.forEach((element: any, index: any) => {
      formData.append(`selfie`, element);
    });

    try {
      const response = await axios.post(
        `${backendURL}${userRoute}/trustSwiftyVerification`,
        formData,
        {
          ...tempHeaders,
          headers: {
            ...tempHeaders.headers,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("trustSwiftyVerification", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export { getUserSettings, updateUserSettings, trustSwiftyVerification };
