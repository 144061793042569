import React, { Children, useState } from "react";
import { Grid, Icon, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface SettingInfoCardProps {
  title: string;
  data: any;
  type: string;
  xs: number;
  md: number;
}

const SettingInfoCard = (props: SettingInfoCardProps) => {
  const [icon, setIcon] = useState(<RemoveRedEyeIcon />);
  const [hidePw, setHidePw] = useState("Password");
  const { title, data, type, xs, md } = props;

  const handleShowToggle = () => {
    if (type === "password") {
      const visibilityEye: any = <VisibilityOffIcon />;
      setIcon(visibilityEye);
      setHidePw(data);
    }
    if (hidePw !== "Password") {
      const removeEye: any = <RemoveRedEyeIcon />;
      setIcon(removeEye);
      setHidePw("Password");
    }
  };

  return (
    <Grid
      component="div"
      item
      xs={xs}
      md={md}
      sx={{
        width: "100%",
        padding: "10px",
        marginBottom: "10px",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          fontWeight={"bold"}
          sx={{ marginBottom: "3px", padding: "10px" }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #EEEEEE",
          padding: "10px",
        }}
      >
        <Typography variant="h6">
          {type === "password" ? hidePw : data}
        </Typography>
        {type === "password" ? (
          <Typography
            component="span"
            onClick={handleShowToggle}
            sx={{ cursor: "pointer" }}
          >
            <Icon children={icon} />
          </Typography>
        ) : (
          " "
        )}
      </Grid>
    </Grid>
  );
};

export default SettingInfoCard;
