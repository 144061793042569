import { Form, Formik, useFormik } from "formik";
import { Button, FormGroup, FormLabel, Grid } from "@mui/material";
import * as Yup from "yup";
import moment from "moment";

import ImgPlaceholder from "../../assets/home-2.jpeg";
import FormControl from "./FormControl";
import { useState } from "react";
import { useAppDispatch } from "../../hooks/hooks";
import { updateUserSettings } from "../../store/thunks/userThunk";
import { LVLpmThemeButton } from "../../utilities/constants";

interface userInfoProps {
  user_id: string;
  img: string;
  email: string;
  firstName: string;
  lastName: string;
  dob: Date | string;
  contact: string;
}

const SettingEditForm = (props: userInfoProps) => {
  const [imgValue, setImgValue] = useState("");
  const dispatch = useAppDispatch();
  const { user_id, img, email, firstName, lastName, dob, contact } = props;

  const initialValues = {
    user_id: user_id,
    img: img === "" ? ImgPlaceholder : img,
    email: email,
    firstName: firstName,
    lastName: lastName,
    dob: moment(dob).format("MM/DD/YYYY"),
    contact: contact,
  };

  const onSubmit = (values: any) => {
    dispatch(updateUserSettings({ values: values })).then(() => {
      location.reload();
    });
  };

  const handleProfileImgChange = (e: any) => {
    e.preventDefault();
    const imgValue = URL.createObjectURL(e.target.files[0]);
    if (imgValue !== "") {
      setImgValue(imgValue);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <Form>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <FormGroup sx={{ marginBottom: "20px" }}>
                <FormLabel htmlFor={"img"} sx={{ fontWeight: "bold" }}>
                  Profile Image
                </FormLabel>

                <img
                  src={imgValue === "" ? img : imgValue}
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    marginBottom: "15px",
                  }}
                />
                <input
                  type="file"
                  id="img"
                  name="img"
                  accept="image/png, image/jpeg"
                  onChange={handleProfileImgChange}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                control="input"
                type="email"
                label="E-mail"
                name="email"
                values={email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                control="input"
                type="firstName"
                label="First Name"
                name="firstName"
                values={firstName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                control="input"
                type="lastName"
                label="Last Name"
                name="lastName"
                values={lastName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                control="date"
                type="dob"
                label="Date of Birth"
                name="dob"
                values={dob}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                control="input"
                type="contact"
                label="Phone Number"
                name="contact"
                values={contact}
              />
            </Grid>
          </Grid>
          <LVLpmThemeButton variant="contained" type="submit">
            Edit
          </LVLpmThemeButton>
        </Form>
      )}
    </Formik>
  );
};

export default SettingEditForm;
