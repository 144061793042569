import { useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";

import RecommendCard from "../recommendCard/recommendCard";
import ImgPlaceholder from "../../../assets/home-4.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useAppSelector } from "../../../hooks/hooks";

const DashboardFooter = () => {
  const [demos] = useState([
    {
      id: 1,
      image: ImgPlaceholder,
      location: "Oregon, USA",
      title: "Remote Retreat",
      description:
        "Welcome to our lonely retreat in the woods. Great for couples looking for a break form the city.",
    },
    {
      id: 2,
      image: ImgPlaceholder,
      location: "Tennessee, USA",
      title: "Bath Garden Stay",
      description:
        "Lovely tranquil retreat meant to guide guests on a meditating, self healing retreat. ",
    },
    {
      id: 3,
      image: ImgPlaceholder,
      location: "South Carolina, USA",
      title: "Home on the Bay",
      description: "This redesigned 4 bedroom home directly on the water...",
    },
  ]);
  const { allProperties } = useAppSelector((state: any) => state.listings);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <Grid container sx={{ position: "relative" }}>
      <Grid
        sx={{
          width: "100vw",
          marginLeft: "-25px",
          height: { xs: "650px", sm: "650px", md: "650px" },
          backgroundImage: "linear-gradient( white, #D5EEF5)",
          position: "absolute",
          zIndex: "-1",
        }}
      ></Grid>

      <Grid item xs={12} sm={12} md={4} lg={4} sx={{ margin: "0 0 25px 0" }}>
        <Typography variant="h4" component="div" fontWeight={"bold"}>
          What's New
        </Typography>
        <Typography variant="body1" component="div" sx={{ margin: "20px 0" }}>
          Learn more about the ever-changing STR landscape. Stay on top of
          trends & more.{" "}
        </Typography>
        <Typography variant="body1" component="div">
          <a href="#">View more</a>
        </Typography>
      </Grid>
      {allProperties?.properties_details &&
        allProperties?.properties_details?.length > 0 && (
          <Carousel
            containerClass="carousel-container"
            itemClass="carousel-item"
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={5000}
            swipeable={true}
            infinite={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            className="dashboardFooter_carousel"
          >
            {allProperties?.properties_details?.map((demo: any) => (
              <RecommendCard
                key={demo.property_id}
                image={demo?.property_images_url.split(",")[0]}
                location={`${demo?.city}, ${demo?.state} ${demo?.country}`}
                title={demo.property_name}
                description={demo?.description}
              />
            ))}
          </Carousel>
        )}
    </Grid>
  );
};

export default DashboardFooter;
