import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Snackbar,
  Box,
  Container,
  useMediaQuery,
} from "@mui/material";
import home from "./../../assets/listings/home.jpg";
import home1 from "./../../assets/home-2.jpeg";
import home3 from "./../../assets/home-3.jpeg";
import home4 from "./../../assets/home-4.jpg";
import DisplayImage from "../../components/reusableComponents/displayCard/displayCard";
import SearchListing from "../../components/reusableComponents/searchListings/searchListings";

import { useAppSelector, useAppDispatch } from "../../hooks/hooks";
import Footer from "../../components/commonComponents/footer/footer";
import { useNavigate } from "react-router-dom";
import { setPreviousLocation } from "../../store";
import { getHomeProperties } from "../../store/thunks/listingsThunk";

const Hone = () => {
  const { previousLocation, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );
  const { homeLocation } = useAppSelector((state: any) => state.listings);
  const dispatch = useAppDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (previousLocation && previousLocation !== "") {
      if (previousLocation?.includes("resetpassword")) {
        setOpenSnackbar(true);
        dispatch(setPreviousLocation(""));
      }
    }
  }, [navigate, loggedIn]);
  useEffect(() => {
    dispatch(getHomeProperties({ value: "" }));
  }, []);

  const matchesX = useMediaQuery("(min-width:900px)");

  return (
    <>
      <Box
        sx={{
          height: "500px",
          width: "100%",
          position: "absolute",
          top: "35%",
          zIndex: "-1",
          backgroundImage:
            "linear-gradient(white, #D5EEF5, #D5EEF5, #D5EEF5, #D5EEF5, white)",
        }}
      ></Box>
      <Grid container sx={{ marginBottom: "100px" }}>
        <Grid
          item
          md={10}
          sm={12}
          xs={12}
          sx={{
            psotion: "relative",
            margin: "30px auto 0 auto",
            height: "500px",
            backgroundImage: `url(${home})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "white",
                textAlign: "center",
              }}
              variant="h4"
            >
              Awaken to a different world
            </Typography>
          </Box>
        </Grid>
        <SearchListing zindex={2} />
      </Grid>
      <Container sx={{ marginBottom: "30px" }}>
        <Grid container sx={{ width: "100%" }}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Enjoy your dream vacation
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#9c9c9c", margin: "10px 0 25px 0" }}
            >
              Plan and book your perfect trip with expert advice, travel tips,
              destination information and inspiration form us.
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {homeLocation
              ? homeLocation.map((element: any, index: any) => {
                  return (
                    <DisplayImage
                      key={index}
                      image={
                        index === 0
                          ? home1
                          : index === 1
                          ? home3
                          : index === 2
                          ? home4
                          : home
                      }
                      title={
                        element.state_name !== ""
                          ? element.state_name.toUpperCase()
                          : element.country_name.toUpperCase()
                      }
                      description={`${element.count} properties `}
                      vacationContent={true}
                      iconContent={false}
                    />
                  );
                })
              : "LOADING..."}
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ marginBottom: "100px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Explore Your Next Stay
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#9c9c9c", margin: "10px 0 25px 0" }}
            >
              Discover a variety of stays, from cozy outdoor getaways and unique
              destinations to entire homes and pet-friendly accommodations.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                backgroundImage: `url(${home4})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "450px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  backgroundImage: "linear-gradient(#313D41, transparent)",
                  width: "100%",
                  padding: "10px 0 10px 10px",
                  opacity: 0.8,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    paddingRight: "100px",
                  }}
                >
                  Outdoor getaways
                </Typography>
                <Typography sx={{ color: "#F39D1B" }}>131 stays</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12} sx={{ marginBottom: "15px" }}>
              <Box
                sx={{
                  backgroundImage: `url(${home4})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "215px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    backgroundImage: "linear-gradient(#313D41, transparent)",
                    width: "100%",
                    padding: "10px 0 10px 10px",
                    opacity: 0.8,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      paddingRight: "100px",
                    }}
                  >
                    Outdoor getaways
                  </Typography>
                  <Typography sx={{ color: "#F39D1B" }}>131 stays</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: { xs: "0", md: "15px" } }}>
              <Box
                sx={{
                  backgroundImage: `url(${home4})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "220px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    backgroundImage: "linear-gradient(#313D41, transparent)",
                    width: "100%",
                    padding: "10px 0 10px 10px",
                    opacity: 0.8,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      paddingRight: "100px",
                    }}
                  >
                    Outdoor getaways
                  </Typography>
                  <Typography sx={{ color: "#F39D1B" }}>131 stays</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                backgroundImage: `url(${home4})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "450px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  backgroundImage: "linear-gradient(#313D41, transparent)",
                  width: "100%",
                  padding: "10px 0 10px 10px",
                  opacity: 0.8,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    paddingRight: "100px",
                  }}
                >
                  Outdoor getaways
                </Typography>
                <Typography sx={{ color: "#F39D1B" }}>131 stays</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ marginBottom: "100px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography sx={{ color: "#00b9d1", marginBottom: "15px" }}>
              Simple, Safe & Secure
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", marginBottom: "40px" }}
              variant="h3"
            >
              How it works?
            </Typography>
          </Grid>
          <DisplayImage
            title={"Owner?"}
            description={
              "List your properties & earn with a worry free mindset. Once you list with us we do the rest."
            }
            iconType={"owner"}
            vacationContent={false}
            iconContent={true}
          />
          <DisplayImage
            title={"Renter?"}
            description={
              "Find what you need. Search by location, or keyword. Filter by price, availability or custom filters. Earn Cash back on every stay & become a local at our destinations."
            }
            iconType={"renter"}
            vacationContent={false}
            iconContent={true}
          />
          <DisplayImage
            title={"Secure Payments"}
            description={
              "Pay with a credit card. We hold your money until you’ve arrived at your stay! Owners are paid after guests check-in."
            }
            iconType={"payment"}
            vacationContent={false}
            iconContent={true}
          />
          <DisplayImage
            title={"Less Fees"}
            description={
              "Simply less fee’s all around. No more hidden service fees, no more sudden upcharges & no more quality issues."
            }
            iconType={"fee"}
            vacationContent={false}
            iconContent={true}
          />
        </Grid>
      </Container>
      <Footer />
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        open={openSnackbar}
        message={"Password reset failed, Please try again"}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      /> */}
    </>
  );
};

export default Hone;
