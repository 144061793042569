import React from "react";
import FormInput from "./FormInput";
import FormDate from "./FormDate";

const FormControl = (props: any) => {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <FormInput {...rest} />;
    case "date":
      return <FormDate {...rest} />;
    default:
      return null;
  }
};

export default FormControl;
