import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utilities/authMidleware";
import { backendURL } from "../../utilities/utils";
import { displayConsoleError } from "../../utilities/helperFunctions";

const addressRoute = "/api/v1/address";

const getUserAddress = createAsyncThunk(
  "address/getUserAddress",
  async ({ user_id }: { user_id: any }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${addressRoute}/getUserAddress`,
        { user_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getUserAddress", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const createUserAddress = createAsyncThunk(
  "address/createUserAddress",
  async ({ values }: { values: Object }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${addressRoute}/createUserAddress`,
        values,
        tokenInterceptor()
      );

      return response.data;
    } catch (error: any) {
      displayConsoleError("createUserAddress", error);
      alert("Something went wrong.");
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const editUserAddress = createAsyncThunk(
  "address/editUserAddress",
  async ({ values }: { values: Object }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${addressRoute}/editUserAddress`,
        values,
        tokenInterceptor()
      );
      alert("Update Successful.");
      return response.data;
    } catch (error: any) {
      displayConsoleError("editUserAddress", error);

      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export { getUserAddress, createUserAddress, editUserAddress };
