import { Grid } from "@mui/material";

import FormControl from "./FormControl";

const PropertyAmenities = () => {
  return (
    <Grid container sx={{ marginTop: "40px" }} spacing={4}>
      <Grid item md={3} sm={12} xs={12} sx={{}}>
        <FormControl
          control="checkbox"
          type="aircondition"
          label="Air Condition"
          name="aircondition"
        />
        <FormControl
          control="checkbox"
          type="balcony"
          label="Balcony"
          name="balcony"
        />
        <FormControl
          control="checkbox"
          type="bathtub"
          label="Bathtub"
          name="bathtub"
        />
        <FormControl
          control="checkbox"
          type="blender"
          label="Blender"
          name="blender"
        />
        <FormControl
          control="checkbox"
          type="bodySoap"
          label="Body Soap"
          name="bodySoap"
        />
        <FormControl
          control="checkbox"
          type="backyard"
          label="Backyard"
          name="backyard"
        />
        <FormControl
          control="checkbox"
          type="barbecueUtensils"
          label="BBQ Utensils"
          name="barbecueUtensils"
        />
        <FormControl
          control="checkbox"
          type="bbqGrill"
          label="BBQ Grill"
          name="bbqGrill"
        />
        <FormControl
          control="checkbox"
          type="beachAccess"
          label="Beach Access"
          name="beachAccess"
        />
        <FormControl
          control="checkbox"
          type="bedLinens"
          label="Bed Linens"
          name="bedLinens"
        />
        <FormControl
          control="checkbox"
          type="carbonMonoxideAlarm"
          label="Carbon Monoxide Alarm"
          name="carbonMonoxideAlarm"
        />
        <FormControl
          control="checkbox"
          type="cleaningAvailableDuringStay"
          label="Cleaning Available During Stay"
          name="cleaningAvailableDuringStay"
        />

        <FormControl
          control="checkbox"
          type="ceilingFan"
          label="Ceiling Fan"
          name="ceilingFan"
        />
        <FormControl
          control="checkbox"
          type="cleaningProducts"
          label="Cleaning Products"
          name="cleaningProducts"
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12} sx={{}}>
        <FormControl
          control="checkbox"
          type="coffee"
          label="Coffee"
          name="coffee"
        />
        <FormControl
          control="checkbox"
          type="coffeeMaker"
          label="Coffee Maker"
          name="coffeeMaker"
        />
        <FormControl
          control="checkbox"
          type="conditioner"
          label="Conditioner"
          name="conditioner"
        />
        <FormControl
          control="checkbox"
          type="cookingBasics"
          label="Cooking Basics"
          name="cookingBasics"
        />
        <FormControl
          control="checkbox"
          type="diningTable"
          label="Dining Table"
          name="diningTable"
        />
        <FormControl
          control="checkbox"
          type="dishwasher"
          label="Dish Washer"
          name="dishwasher"
        />
        <FormControl
          control="checkbox"
          type="dishesAndSilverware"
          label="Dishes And Silverware"
          name="dishesAndSilverware"
        />
        <FormControl
          control="checkbox"
          type="dryer"
          label="Dryer"
          name="dryer"
        />
        <FormControl
          control="checkbox"
          type="essentials"
          label="Essentials"
          name="essentials"
        />
        <FormControl
          control="checkbox"
          type="ethernetConnection"
          label="Ethernet Connection"
          name="ethernetConnection"
        />
        <FormControl
          control="checkbox"
          type="extraPillowsAndBlankets"
          label="Extra Pillows And Blankets"
          name="extraPillowsAndBlankets"
        />
        <FormControl
          control="checkbox"
          type="fireExtinguisher"
          label="Fire Extinguisher"
          name="fireExtinguisher"
        />
        <FormControl
          control="checkbox"
          type="firePit"
          label="Fire Pit"
          name="firePit"
        />
        <FormControl
          control="checkbox"
          type="firstAidKit"
          label="First Aid Kit"
          name="firstAidKit"
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12} sx={{}}>
        <FormControl
          control="checkbox"
          type="freeParking"
          label="Free Parking"
          name="freeParking"
        />
        <FormControl
          control="checkbox"
          type="freezer"
          label="Freezer"
          name="freezer"
        />
        <FormControl
          control="checkbox"
          type="hairDryer"
          label="Hair Dryer"
          name="hairDryer"
        />
        <FormControl
          control="checkbox"
          type="hangers"
          label="Hangers"
          name="hangers"
        />
        <FormControl
          control="checkbox"
          type="hotWater"
          label="Hot Water"
          name="hotWater"
        />
        <FormControl control="checkbox" type="iron" label="Iron" name="iron" />
        <FormControl
          control="checkbox"
          type="kitchen"
          label="Kitchen"
          name="kitchen"
        />
        <FormControl
          control="checkbox"
          type="microwave"
          label="Microwave"
          name="microwave"
        />
        <FormControl control="checkbox" type="oven" label="Oven" name="oven" />
        <FormControl
          control="checkbox"
          type="shampoo"
          label="Shampoo"
          name="shampoo"
        />
        <FormControl
          control="checkbox"
          type="showerGel"
          label="Shower Gel"
          name="showerGel"
        />
        <FormControl
          control="checkbox"
          type="soundSystem"
          label="Sound System"
          name="soundSystem"
        />
        <FormControl
          control="checkbox"
          type="stove"
          label="Stove"
          name="stove"
        />
        <FormControl
          control="checkbox"
          type="toaster"
          label="Toaster"
          name="toaster"
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12} sx={{}}>
        <FormControl control="checkbox" type="tv" label="TV" name="tv" />
        <FormControl
          control="checkbox"
          type="washer"
          label="Washer"
          name="washer"
        />
        <FormControl
          control="checkbox"
          type="wineGlasses"
          label="Wine Glasses"
          name="wineGlasses"
        />
        <FormControl
          control="checkbox"
          type="heating"
          label="Heating"
          name="heating"
        />
        <FormControl
          control="checkbox"
          type="hotWaterKettle"
          label="Hot Water Kettle"
          name="hotWaterKettle"
        />
        <FormControl
          control="checkbox"
          type="longTermStaysAllowed"
          label="Long-Term Stays Allowed"
          name="longTermStaysAllowed"
        />
        <FormControl
          control="checkbox"
          type="luggageDroppoffAllowed"
          label="Luggage Dropp-off Allowed"
          name="luggageDroppoffAllowed"
        />
        <FormControl
          control="checkbox"
          type="outdoorDiningArea"
          label="outdoorDiningArea"
          name="outdoorDiningArea"
        />
        <FormControl
          control="checkbox"
          type="outdoorFurniture"
          label="Outdoor Furniture"
          name="outdoorFurniture"
        />
        <FormControl
          control="checkbox"
          type="patio"
          label="Patio"
          name="patio"
        />
        <FormControl
          control="checkbox"
          type="refridgerator"
          label="Refridgerator"
          name="refridgerator"
        />
        <FormControl
          control="checkbox"
          type="singleLevelHome"
          label="Single Level Home"
          name="singleLevelHome"
        />
        <FormControl
          control="checkbox"
          type="smokeAlarm"
          label="Smoke Alarm"
          name="smokeAlarm"
        />
        <FormControl control="checkbox" type="wifi" label="Wi-Fi" name="wifi" />
      </Grid>
    </Grid>
  );
};

export default PropertyAmenities;
