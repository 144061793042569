import { useEffect, useState } from "react";
// import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Spinner from "../../reusableComponents/spinner/spinner";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { processPayment } from "../../../store/thunks/paymentThunk";
import dayjs from "dayjs";
import { LVLpmThemeButton } from "../../../utilities/constants";
import CloseIcon from "@mui/icons-material/Close";
import {
  applyCancelationPolicy,
  bookingTimeCheck,
  cancelTimeCheck,
  displayConsoleError,
} from "../../../utilities/helperFunctions";
import { useNavigate } from "react-router-dom";
import {
  cancelBooking,
  getBookingPayments,
} from "../../../store/thunks/bookingsThunk";
import { createUserMessageList } from "../../../store/thunks/messagesThunk";
import { setCreatedUserMessageList } from "../../../store";

interface CancelBookingDialogProps {
  open: boolean;
  handleCloseCancel: (event: any) => void;

  propertyDetails: any;
  bookingDetails: any;
}

const CancelBookingDialog = (props: CancelBookingDialogProps) => {
  const { open, handleCloseCancel, propertyDetails, bookingDetails } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { cancelBookingLoading, bookingPayments } = useAppSelector(
    (state: any) => state.bookings
  );
  const { userInfo, supportUsers } = useAppSelector(
    (state: any) => state.authentication
  );

  const [cancellationError, setCancellationError] = useState("");
  const [cancellationMessage, setCancellationMessage] = useState("");
  const [refundType, setRefundType] = useState("");
  const [refundAmount, setRefundAmount] = useState([]);

  const handleCloseCancelBookingDialog = (event: any) => {
    handleCloseCancel("");
  };

  const checkCancelationPolicy = () => {
    const day_to_checking = cancelTimeCheck(bookingDetails?.check_in);
    const booking_date_check = bookingTimeCheck(
      bookingDetails?.booking_create_date
    );

    const cancellation_result = applyCancelationPolicy(
      false,
      day_to_checking,
      booking_date_check,
      bookingDetails?.total_price
    );

    setCancellationMessage(cancellation_result.message);
    setRefundType(cancellation_result.refund_type);
    const refunds: any = [];
    if (cancellation_result.refund_type === "full") {
      setRefundAmount(bookingPayments);
    } else if (cancellation_result.refund_type === "partial") {
      let otherTempRefund = cancellation_result.total_refund;
      let remainingRefund = 0;

      bookingPayments.forEach((refund: any) => {
        if (otherTempRefund > 0) {
          if (otherTempRefund > Number(refund.payment_total)) {
            remainingRefund = otherTempRefund - Number(refund.payment_total);

            refunds.push({
              refund_info: refund,
              amount: Number(refund.payment_total),
            });
            otherTempRefund = remainingRefund;
          } else {
            refunds.push({ refund_info: refund, amount: otherTempRefund });

            otherTempRefund = 0;
            return;
          }
        }
      });
      setRefundAmount(refunds);
    }
  };

  const confirmCancel = () => {
    const cancel_booking_payload: any = {
      property_name: propertyDetails?.property_name,
      name: `${userInfo?.first_name}, ${userInfo?.last_name}`,
      email: userInfo?.user_name,
      check_in: bookingDetails?.check_in,
      check_out: bookingDetails?.check_out,
      booking_id: bookingDetails?.booking_id,
      payment_intent: bookingDetails?.payment_intent_id,
      refunds: refundAmount,
      reason: "requested_by_customer",
      type: refundType,
      lead_id: bookingDetails?.hostfully_lead_id,
    };

    dispatch(cancelBooking({ cancel_booking_payload }))
      .then((canelResult: any) => {
        if (canelResult.payload?.status) {
          handleCloseCancel("Booking has been cancelled successfully");
        } else {
          setCancellationError(
            "Unable to complete refund, please contact customer support"
          );
        }
      })
      .catch((error: any) => {
        setCancellationError(
          "Unable to complete refund, please contact customer support"
        );
      });
  };

  useEffect(() => {
    checkCancelationPolicy();
  }, [bookingPayments]);

  const chatWithSupport = () => {
    const supportDetail = supportUsers
      ? supportUsers?.find((support: any) => support.first_name === "Booking")
      : null;

    const temp_mesage_list_payload = {
      subtitle: bookingDetails?.property_name,
      sender_id: userInfo.user_id,
      reciever_id: supportDetail ? supportDetail?.user_id : 74,
    };

    dispatch(
      createUserMessageList({ message_list_payload: temp_mesage_list_payload })
    )
      .then((result: any) => {
        if (result?.payload?.message_list) {
          dispatch(setCreatedUserMessageList(result?.payload?.message_list));
        }
        handleCloseCancelBookingDialog("");
        navigate("/messages");
      })
      .catch((error: any) => {
        displayConsoleError("messageHost", error);
        navigate("/messages");
      });
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Spinner loading={cancelBookingLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Booking Cancelation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseCancelBookingDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Typography>{cancellationMessage}</Typography>
        <Typography>
          Are you sure you would like to cancel your booking. We hate to see you
          go and please feel free to reach out to us if there is anything we can
          assist you with. Thank you!
        </Typography>
        {cancellationError !== "" && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "red",
            }}
          >
            <Typography>{cancellationError}</Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => chatWithSupport()}>
          Chat With Support
        </Button>
        <Button variant="outlined" onClick={() => confirmCancel()}>
          Confirm & Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelBookingDialog;
