import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../hooks/hooks";
import dayjs from "dayjs";

interface PaymentDialogProps {
  open: boolean;
  handleClose: () => void;
  leads: any;
}

const PropertyBookingDialog = (props: PaymentDialogProps) => {
  const { open, handleClose, leads } = props;

  const { selectedPropertyDetails } = useAppSelector(
    (state: any) => state.listings
  );
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      style={{ minWidth: "1220px" }}
    >
      <Grid container style={{ backgroundColor: "blue" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Property Booking
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {selectedPropertyDetails?.property_name}
          </Typography>
        </Grid>
        <Grid container item xs={12} sx={{ padding: "10px 10px 10px 0px" }}>
          <Typography>
            This property has already an active reservation.
          </Typography>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              From: {dayjs(leads?.check_in).format("MMM, D YYYY")} To{" "}
              {dayjs(leads?.check_out).format("MMM, D YYYY")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sx={{ padding: "10px 10px 10px 0px" }}>
          <Typography>Please select a different timeframe</Typography>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose()}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PropertyBookingDialog;
