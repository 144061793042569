import React from "react";
import { Grid, Modal, Typography, useMediaQuery } from "@mui/material";
import AddressEditForm from "./AddressAddForm";
import AddressAddForm from "./AddressAddForm";

interface ModalProps {
  open: boolean;
  handleClose: any;
  user_id: number;
}

const AddressAddModal = (props: ModalProps) => {
  const { open, handleClose, user_id } = props;

  const matchesX = useMediaQuery("(min-width:1100px)");
  const matchesY = useMediaQuery("(min-height:1200px)");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid
        sx={{
          overflowY: matchesY && matchesX ? "none" : "scroll",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "65%",
          maxHeight: "55%",
          bgcolor: "background.paper",
          boxShadow: 30,
          p: 0,
          borderRadius: "15px",
          outline: 0,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: "bold",
            padding: "10px 0 10px 10px",
            backgroundColor: "#00B9D1",
            color: "#fff",
            width: "100%",
          }}
        >
          Edit Address
        </Typography>
        <AddressAddForm user_id={user_id} />
      </Grid>
    </Modal>
  );
};

export default AddressAddModal;
