import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Spinner from "../../reusableComponents/spinner/spinner";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  cancelPaymentIntent,
  checkLeads,
  paymentIntent,
} from "../../../store/thunks/paymentThunk";
import dayjs from "dayjs";
import { LVLpmThemeButton } from "../../../utilities/constants";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { loadStripe } from "@stripe/stripe-js";
import {
  applyCancelationPolicy,
  bookingTimeCheck,
  cancelTimeCheck,
  dateRangeOverlap,
  displayConsoleError,
  processBookingFees,
  updateBookingCheckDates,
} from "../../../utilities/helperFunctions";
import {
  getPropertyBookings,
  updateBooking,
} from "../../../store/thunks/bookingsThunk";
import UpdateBookingForm from "../paymentDialog/updateBookingForm";
import { resetPayment } from "../../../store/features/payment/paymentSlice";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { createUserMessageList } from "../../../store/thunks/messagesThunk";

import { useNavigate } from "react-router-dom";
import { setCreatedUserMessageList } from "../../../store";

interface UpdateBookingDialogProps {
  open: boolean;
  handleCloseUpdate: (event: any) => void;
  propertyDetails: any;
  bookingDetails: any;
}

const UpdateBookingDialog = (props: UpdateBookingDialogProps) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { open, handleCloseUpdate, propertyDetails, bookingDetails } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { updateBookingLoading, bookingPayments } = useAppSelector(
    (state: any) => state.bookings
  );

  const { userInfo, supportUsers } = useAppSelector(
    (state: any) => state.authentication
  );

  const [submited, setSubmitted] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [changeFee, setChangeFee] = useState(false);

  const [address, setAddress] = useState<any>({});
  const [enableExtension, setEnableExtension] = useState(false);
  const [enableRefund, setEnableRefund] = useState(false);
  const [refundMessage, setRefundMessage] = useState(false);
  const [existingBooking, setExistingBooking] = useState(false);
  const [enableUpdate, setEnableUpdate] = useState(false);

  const [clientSecret, setClientSecret] = useState("");
  const [newPaymentIntentId, setNewPaymentIntentId] = useState("");
  const [stripePromise, setStripePromise] = useState<any>(null);

  const [newCheckin, setNewCheckin] = useState<any>(undefined);
  const [newCheckout, setNewCheckout] = useState<any>(undefined);
  const [newGuests, setNewGuests] = useState<any>(undefined);

  const [nights, setNights] = useState<any>(0);

  const [bookingFees, setBookingFees] = useState<any>({
    nights: 0,
    pricePerDay: 0,
    stayPrice: 0,
    lvlpmFee: 0,
    taxes: 0,
    newTotalPrice: 0,
    charge: false,
    refund: false,
  });

  const [totalRefund, setTotalRefund] = useState<any>(null);

  const [propertyLeads, setPropertyLeads] = useState<any>({});

  const handleCloseUpdateBookingDialog = (event: any) => {
    setNewCheckin(bookingDetails?.check_in);
    setNewCheckout(bookingDetails?.check_out);
    setNewGuests(bookingDetails?.number_of_guests);

    setNewPaymentIntentId("");
    setClientSecret("");
    dispatch(resetPayment());
    setPropertyLeads({});

    setBookingFees({
      nights: 0,
      pricePerDay: 0,
      stayPrice: 0,
      lvlpmFee: 0,
      taxes: 0,
      newTotalPrice: 0,
      charges: false,
      refund: false,
    });
    setChangeFee(false);

    setExistingBooking(false);
    setEnableRefund(false);
    setEnableExtension(false);
    setTotalRefund(null);
    handleCloseUpdate(event);
  };

  const initialSetup = () => {
    setNewCheckin(bookingDetails?.check_in);
    setNewCheckout(bookingDetails?.check_out);
    setNewGuests(bookingDetails?.number_of_guests);
  };

  useEffect(() => {
    initialSetup();
  }, [bookingDetails]);

  const checkUpdateRequest = (
    newCheckin: any,
    newCheckout: any,
    newGuests: number
  ) => {
    let temp_new_check_in: any = dayjs(
      new Date(newCheckin).setHours(8, 0, 0, 0)
    );
    let temp_new_check_out: any = dayjs(
      new Date(newCheckout).setHours(8, 0, 0, 0)
    );
    let temp_current_check_in: any = dayjs(
      new Date(bookingDetails?.check_in).setHours(8, 0, 0, 0)
    );
    let temp_current_check_out: any = dayjs(
      new Date(bookingDetails?.check_out).setHours(8, 0, 0, 0)
    );

    setUpdateErrorMessage("");
    setUpdateError(false);

    setBookingFees({
      nights: 0,
      pricePerDay: 0,
      stayPrice: 0,
      lvlpmFee: 0,
      taxes: 0,
      newTotalPrice: 0,
      charge: false,
      refund: false,
    });
    setExistingBooking(false);
    setEnableRefund(false);
    setEnableExtension(false);
    setTotalRefund(null);

    if (
      temp_new_check_in.format("YYYY-MM-DD") ===
        temp_current_check_in.format("YYYY-MM-DD") &&
      temp_new_check_out.format("YYYY-MM-DD") ===
        temp_current_check_out.format("YYYY-MM-DD")
    ) {
      return;
    }

    const number_of_days = Math.round(
      (temp_new_check_out - temp_new_check_in) / (1000 * 60 * 60 * 24)
    );

    if (number_of_days < 3) {
      setUpdateErrorMessage(
        "A minimum of 3 nights is required. Please adjust your booking dates!"
      );
      setUpdateError(true);
      return;
    }

    let full_refund: boolean = false;

    const day_to_checking = cancelTimeCheck(bookingDetails?.check_in);
    const booking_date_check = bookingTimeCheck(
      bookingDetails?.booking_create_date
    );
    let change_fee = false;

    if (day_to_checking >= 30 || booking_date_check < 1) {
      full_refund = true;
    } else {
      change_fee = true;
    }

    setChangeFee(change_fee);

    let update_booking_info = updateBookingCheckDates(
      [temp_current_check_in, temp_current_check_out],
      [temp_new_check_in, temp_new_check_out]
    );

    let all_temp_booking_fees: any = { ...update_booking_info };

    if (newPaymentIntentId !== "") {
      dispatch(cancelPaymentIntent({ paymentIntent: newPaymentIntentId }));
      setNewPaymentIntentId("");
      setClientSecret("");
    }

    let temp_booking_fees: any;

    if (update_booking_info?.refund) {
      temp_booking_fees = processBookingFees(
        propertyDetails,
        number_of_days,
        false
      );

      all_temp_booking_fees = {
        ...all_temp_booking_fees,
        nights: number_of_days,
        ...temp_booking_fees,
      };
    } else if (update_booking_info?.charge || update_booking_info?.update) {
      temp_booking_fees = processBookingFees(
        propertyDetails,
        update_booking_info?.days_difference,
        change_fee
      );

      all_temp_booking_fees = {
        ...all_temp_booking_fees,
        nights: update_booking_info?.days_difference,
        ...temp_booking_fees,
      };
    }

    setBookingFees(all_temp_booking_fees);

    if (update_booking_info?.lead_check) {
      let leadChecking: Boolean = true;
      let checking: Boolean = true;

      const leadsInfo: any = {
        hostfully_id: propertyDetails.hostfully_property_id,
        lead_range: update_booking_info?.lead_check_range,
      };

      dispatch(checkLeads({ leadsInfo }))
        .then((leadResult: any) => {
          if (
            leadResult.payload?.leads &&
            leadResult.payload?.leads.length > 0
          ) {
            leadResult.payload?.leads.forEach((currentLeads: any) => {
              if (["NEW", "PENDING", "BOOKED"].includes(currentLeads?.status)) {
                update_booking_info?.lead_check_range.forEach(
                  (update_range: any) => {
                    const check = dateRangeOverlap(
                      [
                        dayjs(new Date(update_range.check_in)),
                        dayjs(new Date(update_range.check_out)),
                      ],
                      [
                        dayjs(
                          new Date(currentLeads?.checkInZonedDateTime).setHours(
                            8,
                            0,
                            0,
                            0
                          )
                        ),
                        dayjs(
                          new Date(
                            currentLeads?.checkOutZonedDateTime
                          ).setHours(8, 0, 0, 0)
                        ),
                      ]
                    );

                    if (check === false) {
                      leadChecking = false;
                      setPropertyLeads({
                        check_in: currentLeads?.checkInZonedDateTime,
                        check_out: currentLeads?.checkOutZonedDateTime,
                      });
                      setExistingBooking(true);
                      setEnableRefund(false);
                      setEnableUpdate(false);
                      return;
                    }
                  }
                );
              }
            });
            if (leadChecking) {
              dispatch(
                getPropertyBookings({
                  property_id: propertyDetails.property_id,
                })
              )
                .then((result: any) => {
                  if (
                    result.payload?.property_bookings &&
                    result.payload?.property_bookings.length > 0
                  ) {
                    result.payload?.property_bookings.forEach(
                      (property_booking: any) => {
                        update_booking_info?.lead_check_range.forEach(
                          (update_range: any) => {
                            const check = dateRangeOverlap(
                              [
                                dayjs(
                                  new Date(update_range.check_in).setHours(
                                    8,
                                    0,
                                    0,
                                    0
                                  )
                                ),
                                dayjs(
                                  new Date(update_range.check_out).setHours(
                                    8,
                                    0,
                                    0,
                                    0
                                  )
                                ),
                              ],
                              [
                                dayjs(
                                  new Date(property_booking?.check_in).setHours(
                                    8,
                                    0,
                                    0,
                                    0
                                  )
                                ),
                                dayjs(
                                  new Date(
                                    property_booking?.check_out
                                  ).setHours(8, 0, 0, 0)
                                ),
                              ]
                            );
                            if (check === false) {
                              checking = false;
                              setPropertyLeads({
                                check_in: property_booking?.check_in,
                                check_out: property_booking?.check_out,
                              });
                              setExistingBooking(true);
                              setEnableRefund(false);
                              setEnableExtension(false);
                              return;
                            }
                          }
                        );
                      }
                    );
                    if (checking) {
                      setExistingBooking(false);
                      if (newPaymentIntentId !== "") {
                        dispatch(
                          cancelPaymentIntent({
                            paymentIntent: newPaymentIntentId,
                          })
                        );
                        setNewPaymentIntentId("");
                        setClientSecret("");
                      }

                      if (update_booking_info?.refund) {
                        temp_booking_fees = processBookingFees(
                          propertyDetails,
                          update_booking_info?.days_difference,
                          false
                        );

                        const cancellation_result = applyCancelationPolicy(
                          change_fee,
                          day_to_checking,
                          booking_date_check,
                          temp_booking_fees?.newTotalPrice
                        );

                        setTotalRefund({
                          ...temp_booking_fees,
                          ...cancellation_result,
                        });
                        setExistingBooking(false);
                        setEnableRefund(true);
                        setEnableExtension(false);
                      } else if (update_booking_info?.charge) {
                        dispatch(
                          paymentIntent({
                            amount: temp_booking_fees?.newTotalPrice,
                          })
                        )
                          .then((result: any) => {
                            if (result?.payload?.clientSecret) {
                              setClientSecret(result.payload.clientSecret);
                              setNewPaymentIntentId(
                                result.payload.paymentIntentId
                              );
                              setStripePromise(
                                loadStripe(result.payload.stripePromise)
                              );
                              setExistingBooking(false);
                              setEnableRefund(false);
                              setEnableExtension(true);
                            }
                          })
                          .catch((error) => {
                            displayConsoleError("paymentIntent", error);
                          });
                      } else if (update_booking_info?.update) {
                        if (temp_booking_fees?.newTotalPrice > 0) {
                          dispatch(
                            paymentIntent({
                              amount: temp_booking_fees?.newTotalPrice,
                            })
                          )
                            .then((result: any) => {
                              if (result?.payload?.clientSecret) {
                                setClientSecret(result.payload.clientSecret);
                                setNewPaymentIntentId(
                                  result.payload.paymentIntentId
                                );
                                setStripePromise(
                                  loadStripe(result.payload.stripePromise)
                                );
                                setExistingBooking(false);
                                setEnableRefund(false);
                                setEnableExtension(true);
                              }
                            })
                            .catch((error) => {
                              displayConsoleError("paymentIntent", error);
                            });
                        } else {
                          temp_booking_fees = processBookingFees(
                            propertyDetails,
                            number_of_days,
                            false
                          );
                          setBookingFees({
                            ...all_temp_booking_fees,
                            ...temp_booking_fees,
                            nights: number_of_days,
                          });
                          setExistingBooking(false);
                          setEnableRefund(true);
                          setEnableExtension(false);
                        }
                      }
                    }
                  } else {
                    if (newPaymentIntentId !== "") {
                      dispatch(
                        cancelPaymentIntent({
                          paymentIntent: newPaymentIntentId,
                        })
                      );
                      setNewPaymentIntentId("");
                      setClientSecret("");
                    }
                    if (update_booking_info?.refund) {
                      temp_booking_fees = processBookingFees(
                        propertyDetails,
                        update_booking_info?.days_difference,
                        false
                      );
                      const cancellation_result = applyCancelationPolicy(
                        change_fee,
                        day_to_checking,
                        booking_date_check,
                        temp_booking_fees?.newTotalPrice
                      );

                      setTotalRefund({
                        ...temp_booking_fees,
                        ...cancellation_result,
                      });
                      setExistingBooking(false);
                      setEnableRefund(true);
                      setEnableExtension(false);
                    } else if (update_booking_info?.charge) {
                      dispatch(
                        paymentIntent({
                          amount: temp_booking_fees?.newTotalPrice,
                        })
                      )
                        .then((result: any) => {
                          if (result?.payload?.clientSecret) {
                            setClientSecret(result.payload.clientSecret);
                            setNewPaymentIntentId(
                              result.payload.paymentIntentId
                            );
                            setStripePromise(
                              loadStripe(result.payload.stripePromise)
                            );
                            setExistingBooking(false);
                            setEnableRefund(false);
                            setEnableExtension(true);
                          }
                        })
                        .catch((error) => {
                          displayConsoleError("paymentIntent", error);
                        });
                    } else if (update_booking_info?.update) {
                      if (temp_booking_fees?.newTotalPrice > 0) {
                        dispatch(
                          paymentIntent({
                            amount: temp_booking_fees?.newTotalPrice,
                          })
                        )
                          .then((result: any) => {
                            if (result?.payload?.clientSecret) {
                              setClientSecret(result.payload.clientSecret);
                              setNewPaymentIntentId(
                                result.payload.paymentIntentId
                              );
                              setStripePromise(
                                loadStripe(result.payload.stripePromise)
                              );
                              setExistingBooking(false);
                              setEnableRefund(false);
                              setEnableExtension(true);
                            }
                          })
                          .catch((error) => {
                            displayConsoleError("paymentIntent", error);
                          });
                      } else {
                        temp_booking_fees = processBookingFees(
                          propertyDetails,
                          number_of_days,
                          false
                        );
                        setBookingFees({
                          ...all_temp_booking_fees,
                          ...temp_booking_fees,
                          nights: number_of_days,
                        });
                        setExistingBooking(false);
                        setEnableRefund(true);
                        setEnableExtension(false);
                      }
                    }
                  }
                })
                .catch((error) => {
                  displayConsoleError("getPropertyBookings", error);
                });
            }
          } else {
            dispatch(
              getPropertyBookings({
                property_id: propertyDetails.property_id,
              })
            )
              .then((result: any) => {
                if (
                  result.payload?.property_bookings &&
                  result.payload?.property_bookings.length > 0
                ) {
                  let checking: Boolean = true;
                  result.payload?.property_bookings.forEach(
                    (property_booking: any) => {
                      update_booking_info?.lead_check_range.forEach(
                        (update_range: any) => {
                          const check = dateRangeOverlap(
                            [
                              dayjs(
                                new Date(update_range.check_in).setHours(
                                  8,
                                  0,
                                  0,
                                  0
                                )
                              ),
                              dayjs(
                                new Date(update_range.check_out).setHours(
                                  8,
                                  0,
                                  0,
                                  0
                                )
                              ),
                            ],
                            [
                              dayjs(
                                new Date(property_booking?.check_in).setHours(
                                  8,
                                  0,
                                  0,
                                  0
                                )
                              ),
                              dayjs(
                                new Date(property_booking?.check_out).setHours(
                                  8,
                                  0,
                                  0,
                                  0
                                )
                              ),
                            ]
                          );

                          if (check === false) {
                            checking = false;
                            setPropertyLeads({
                              check_in: property_booking?.check_in,
                              check_out: property_booking?.check_out,
                            });
                            setExistingBooking(true);
                            setEnableRefund(false);
                            setEnableExtension(false);
                            return;
                          }
                        }
                      );
                    }
                  );
                  if (checking) {
                    setExistingBooking(false);
                    if (newPaymentIntentId !== "") {
                      dispatch(
                        cancelPaymentIntent({
                          paymentIntent: newPaymentIntentId,
                        })
                      );
                      setNewPaymentIntentId("");
                      setClientSecret("");
                    }
                    if (update_booking_info?.refund) {
                      temp_booking_fees = processBookingFees(
                        propertyDetails,
                        update_booking_info?.days_difference,
                        false
                      );

                      const cancellation_result = applyCancelationPolicy(
                        change_fee,
                        day_to_checking,
                        booking_date_check,
                        temp_booking_fees?.newTotalPrice
                      );

                      setTotalRefund({
                        ...temp_booking_fees,
                        ...cancellation_result,
                      });
                      setExistingBooking(false);
                      setEnableRefund(true);
                      setEnableExtension(false);
                    } else if (update_booking_info?.charge) {
                      if (temp_booking_fees?.newTotalPrice > 0) {
                        dispatch(
                          paymentIntent({
                            amount: temp_booking_fees?.newTotalPrice,
                          })
                        )
                          .then((result: any) => {
                            if (result?.payload?.clientSecret) {
                              setClientSecret(result.payload.clientSecret);
                              setNewPaymentIntentId(
                                result.payload.paymentIntentId
                              );
                              setStripePromise(
                                loadStripe(result.payload.stripePromise)
                              );
                              setExistingBooking(false);
                              setEnableRefund(false);
                              setEnableExtension(true);
                            }
                          })
                          .catch((error) => {
                            displayConsoleError("paymentIntent", error);
                          });
                      } else {
                        temp_booking_fees = processBookingFees(
                          propertyDetails,
                          number_of_days,
                          false
                        );

                        setBookingFees({
                          ...temp_booking_fees,
                          nights: number_of_days,
                        });
                        setExistingBooking(false);
                        setEnableExtension(false);
                        setEnableRefund(true);
                      }
                    } else if (update_booking_info?.update) {
                      if (temp_booking_fees?.newTotalPrice > 0) {
                        dispatch(
                          paymentIntent({
                            amount: temp_booking_fees?.newTotalPrice,
                          })
                        )
                          .then((result: any) => {
                            if (result?.payload?.clientSecret) {
                              setClientSecret(result.payload.clientSecret);
                              setNewPaymentIntentId(
                                result.payload.paymentIntentId
                              );
                              setStripePromise(
                                loadStripe(result.payload.stripePromise)
                              );
                              setExistingBooking(false);
                              setEnableRefund(false);
                              setEnableExtension(true);
                            }
                          })
                          .catch((error) => {
                            displayConsoleError("paymentIntent", error);
                          });
                      } else {
                        temp_booking_fees = processBookingFees(
                          propertyDetails,
                          number_of_days,
                          false
                        );
                        setBookingFees({
                          ...all_temp_booking_fees,
                          ...temp_booking_fees,
                          nights: number_of_days,
                        });
                        setExistingBooking(false);
                        setEnableRefund(true);
                        setEnableExtension(false);
                      }
                    }
                  }
                } else {
                  if (newPaymentIntentId !== "") {
                    dispatch(
                      cancelPaymentIntent({
                        paymentIntent: newPaymentIntentId,
                      })
                    );
                    setNewPaymentIntentId("");
                    setClientSecret("");
                  }
                  if (update_booking_info?.refund) {
                    temp_booking_fees = processBookingFees(
                      propertyDetails,
                      update_booking_info?.days_difference,
                      false
                    );
                    const cancellation_result = applyCancelationPolicy(
                      change_fee,
                      day_to_checking,
                      booking_date_check,
                      temp_booking_fees?.newTotalPrice
                    );

                    setTotalRefund({
                      ...temp_booking_fees,
                      ...cancellation_result,
                    });
                    setExistingBooking(false);
                    setEnableRefund(true);
                    setEnableExtension(false);
                  } else if (update_booking_info?.charge) {
                    dispatch(
                      paymentIntent({
                        amount: temp_booking_fees?.newTotalPrice,
                      })
                    )
                      .then((result: any) => {
                        if (result?.payload?.clientSecret) {
                          setClientSecret(result.payload.clientSecret);
                          setNewPaymentIntentId(result.payload.paymentIntentId);
                          setStripePromise(
                            loadStripe(result.payload.stripePromise)
                          );
                          setExistingBooking(false);
                          setEnableRefund(false);
                          setEnableUpdate(false);
                        }
                      })
                      .catch((error) => {
                        displayConsoleError("paymentIntent", error);
                      });
                  } else if (update_booking_info?.update) {
                    if (temp_booking_fees?.newTotalPrice > 0) {
                      dispatch(
                        paymentIntent({
                          amount: temp_booking_fees?.newTotalPrice,
                        })
                      )
                        .then((result: any) => {
                          if (result?.payload?.clientSecret) {
                            setClientSecret(result.payload.clientSecret);
                            setNewPaymentIntentId(
                              result.payload.paymentIntentId
                            );
                            setStripePromise(
                              loadStripe(result.payload.stripePromise)
                            );
                            setExistingBooking(false);
                            setEnableRefund(false);
                            setEnableExtension(true);
                          }
                        })
                        .catch((error) => {
                          displayConsoleError("paymentIntent", error);
                        });
                    } else {
                      temp_booking_fees = processBookingFees(
                        propertyDetails,
                        number_of_days,
                        false
                      );
                      setBookingFees({
                        ...all_temp_booking_fees,
                        ...temp_booking_fees,
                        nights: number_of_days,
                      });
                      setExistingBooking(false);
                      setEnableRefund(true);
                      setEnableExtension(false);
                    }
                  }
                }
              })
              .catch((error) => {
                displayConsoleError("getPropertyBookings", error);
              });
          }
        })
        .catch((leadsInfoCheckingError: any) => {});
    } else if (update_booking_info?.refund) {
      temp_booking_fees = processBookingFees(
        propertyDetails,
        update_booking_info?.days_difference,
        false
      );
      const cancellation_result = applyCancelationPolicy(
        change_fee,
        day_to_checking,
        booking_date_check,
        temp_booking_fees?.newTotalPrice
      );

      setTotalRefund({ ...temp_booking_fees, ...cancellation_result });
      setExistingBooking(false);
      setEnableRefund(true);
      setEnableExtension(false);
    }
  };

  const submitUpdateRequest = () => {
    const refunds: any = [];

    let otherTempRefund = totalRefund?.total_refund;
    let remainingRefund = 0;

    bookingPayments.forEach((refund: any) => {
      if (otherTempRefund > 0) {
        if (otherTempRefund > Number(refund.payment_total)) {
          remainingRefund = otherTempRefund - Number(refund.payment_total);

          refunds.push({
            refund_info: refund,
            amount: Number(refund.payment_total),
          });
          otherTempRefund = remainingRefund;
        } else {
          refunds.push({ refund_info: refund, amount: otherTempRefund });

          otherTempRefund = 0;
          return;
        }
      }
    });

    const updateBookingPaylod = {
      user_id: userInfo?.user_id,
      name: `${userInfo?.first_name}, ${userInfo?.last_name}`,
      email: userInfo?.email,
      property_name: propertyDetails?.property_name,
      property_id: propertyDetails?.property_id,
      hostfully_id: propertyDetails?.hostfully_property_id,
      booking_id: bookingDetails?.booking_id,
      check_in: dayjs(bookingDetails.check_in).format("YYYY-MM-DDTHH:mm:ss"),
      new_check_in: dayjs(newCheckin).format("YYYY-MM-DDTHH:mm:ss"),
      new_check_out: dayjs(newCheckout).format("YYYY-MM-DDTHH:mm:ss"),
      nights: nights,
      guests: newGuests,
      bookingFees: bookingFees,
      refundAmmount: refunds,
      totalRefund: totalRefund,
      reason: "requested_by_customer",
      lead_id: bookingDetails?.hostfully_lead_id,
    };

    dispatch(updateBooking({ update_booking_payload: updateBookingPaylod }))
      .then(async (paymentResult: any) => {
        handleCloseUpdateBookingDialog(
          "Booking Update has been completed successfully"
        );
      })
      .catch((error) => {
        displayConsoleError("processPayment", error);
        handleCloseUpdateBookingDialog(
          "Unable to complete booking update. Please try again later or contact customer support"
        );
      });
  };

  const contactSupport = () => {
    const supportDetail = supportUsers
      ? supportUsers?.find((support: any) => support.first_name === "Booking")
      : null;

    const temp_mesage_list_payload = {
      subtitle: bookingDetails?.property_name,
      sender_id: userInfo.user_id,
      reciever_id: supportDetail ? supportDetail?.user_id : 74,
    };
    dispatch(
      createUserMessageList({ message_list_payload: temp_mesage_list_payload })
    )
      .then((result: any) => {
        if (result?.payload?.message_list) {
          dispatch(setCreatedUserMessageList(result?.payload?.message_list));
        }
        handleCloseUpdateBookingDialog("");
        navigate("/messages");
      })
      .catch((error) => {
        displayConsoleError("messageHost", error);
        handleCloseUpdateBookingDialog("");
        navigate("/messages");
      });
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Spinner loading={updateBookingLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Update Booking
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseUpdateBookingDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Container sx={{}}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} sx={{}}>
              <Typography variant="h5">Reservation</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Check In
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="checkIn"
                    value={dayjs(new Date(newCheckin)).utc()}
                    onChange={(value: any) => {
                      setNewCheckin(value);
                      checkUpdateRequest(value, newCheckout, newGuests);
                    }}
                    disablePast
                    views={["year", "month", "day"]}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                Check Out
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="checkOut"
                  value={dayjs(new Date(newCheckout)).utc()}
                  onChange={(value: any) => {
                    setNewCheckout(value);
                    checkUpdateRequest(newCheckin, value, newGuests);
                  }}
                  disablePast
                  views={["year", "month", "day"]}
                  sx={{ width: "100%" }}
                  minDate={dayjs(newCheckin)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Guests
                </Typography>
                <Select
                  name="guests"
                  id="guest"
                  value={newGuests}
                  defaultValue={bookingDetails?.number_of_guests}
                  onChange={(event) => {
                    setNewGuests(event.target.value);
                    checkUpdateRequest(
                      newCheckin,
                      newCheckout,
                      event.target.value
                    );
                  }}
                  label="Guests"
                  sx={{
                    padding: "10px 0",
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {Array.from({ length: 10 }, (v, k) => k + 1).map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    );
                  })}
                  <MenuItem value={10}>{2} guests</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12} sx={{}}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                $ 80 /Night
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container sx={{ marginTop: "25px" }}>
          {existingBooking && (
            <Grid>
              <Grid
                container
                item
                xs={12}
                sx={{ padding: "10px 10px 10px 0px" }}
              >
                <Typography>
                  This property has already an active reservation.
                </Typography>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    From: {dayjs(propertyLeads?.check_in).format("MMM, D YYYY")}{" "}
                    To {dayjs(propertyLeads?.check_out).format("MMM, D YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sx={{ padding: "10px 10px 10px 0px" }}
              >
                <Typography>Please select a different timeframe</Typography>
              </Grid>
            </Grid>
          )}
          {(enableExtension || enableRefund) && (
            <Grid container item xs={12} sx={{}}>
              <Grid item xs={6} sx={{}}>
                <Paper sx={{ margin: 2 }} elevation={3}>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      padding: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: "bold", margin: "10px 0px 10px 0px" }}
                      >
                        {enableExtension ? "Extension" : "Booking"} Price
                        Details
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "0px 0px 5px 0px",
                      }}
                    >
                      <Grid>
                        {propertyDetails.state === "CA"
                          ? propertyDetails.price_per_day
                          : bookingFees?.pricePerDay}{" "}
                        x {bookingFees?.nights}/ nights
                      </Grid>
                      <Grid>$ {bookingFees?.stayPrice}</Grid>
                    </Grid>
                    {propertyDetails.state === "CA" && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          margin: "0px 0px 5px 0px",
                        }}
                      >
                        <Grid>LVLPm Fee (.033%)</Grid>
                        <Grid>$ {bookingFees?.lvlpmFee}</Grid>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "0px 0px 5px 0px",
                      }}
                    >
                      <Grid>Taxes</Grid>
                      <Grid>$ {bookingFees?.taxes}</Grid>
                    </Grid>
                    {changeFee && enableExtension && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          margin: "0px 0px 5px 0px",
                        }}
                      >
                        <Grid>Change Fee</Grid>
                        <Grid>$ 50</Grid>
                      </Grid>
                    )}
                    <Divider sx={{ width: "100%", margin: "0px 0px 5px 0px" }}>
                      {" "}
                    </Divider>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "0px 0px 5px 0px",
                      }}
                    >
                      <Grid sx={{ fontWeight: "bold" }}>Total (USD)</Grid>
                      <Grid sx={{ fontWeight: "bold" }}>
                        $ {bookingFees?.newTotalPrice}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6} sx={{}}>
                {clientSecret && stripePromise && bookingFees?.charge && (
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <UpdateBookingForm
                      handleCloseUpdateForm={(event) =>
                        handleCloseUpdateBookingDialog(event)
                      }
                      bookingFees={bookingFees}
                      paymentIntentId={newPaymentIntentId}
                      nights={bookingFees?.nights}
                      guests={newGuests}
                      propertyDetails={propertyDetails}
                      bookingDetails={bookingDetails}
                      check_in={newCheckin}
                      check_out={newCheckout}
                    />
                  </Elements>
                )}
              </Grid>
            </Grid>
          )}
          {enableRefund && totalRefund?.total_refund > 0 && (
            <Grid item xs={12} sx={{ marginBottom: "10px" }}>
              <Typography>{totalRefund?.message}</Typography>
            </Grid>
          )}
          {updateError && <Typography>{updateErrorMessage}</Typography>}
        </Container>
      </DialogContent>
      <DialogActions>
        {enableExtension === false && (
          <LVLpmThemeButton
            variant="contained"
            onClick={() => contactSupport()}
          >
            Chat With Support
          </LVLpmThemeButton>
        )}
        {enableRefund && (
          <LVLpmThemeButton
            variant="contained"
            onClick={() => submitUpdateRequest()}
          >
            Submit Request
          </LVLpmThemeButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UpdateBookingDialog;
