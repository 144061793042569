import React, { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import CloseIcon from "@mui/icons-material/Close";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import HomeIcon from "@mui/icons-material/Home";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";

import ImgPlaceholder from "../../assets/home-2.jpeg";
import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import SettingInfoCard from "../../components/reusableComponents/settingInfoCard/settingInfoCard";
import SettingEditForm from "./settingEditForm";
import moment from "moment";
import { getUserSettings } from "../../store/thunks/userThunk";
import IconOptionCard from "../../components/reusableComponents/iconOptionCard/iconOptionCard";
import ProfileInfoPage from "./settingsSubPage/profile/ProfileInfoPage";
import AddressInfoPage from "./settingsSubPage/address/AddressInfoPage";
import SettingsSecurityPage from "./settingsSubPage/security/SettingsSecurityPage";

const SettingsPage = () => {
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const { user_id }: any = userInfo;
  const [options] = useState([
    {
      id: 0,
      img: PermIdentityOutlinedIcon,
      title: "Personal Info",
      route: "profile",
    },
    {
      id: 1,
      img: HomeIcon,
      title: "Address Info",
      route: "address",
    },
    {
      id: 2,
      img: HttpsOutlinedIcon,
      title: "Security",
      route: "security",
    },
  ]);

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    dob: "",
    contact: "",
  });
  const [settingsPage, setSettingsPage] = useState("profile");

  const { loading, error, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
    if (userInfo) {
      dispatch(getUserSettings({ user_id })).then((res) =>
        setUser(res.payload.user)
      );
    }
  }, [navigate, loggedIn]);

  const matchesX = useMediaQuery("(min-width:1100px)");
  const matchesY = useMediaQuery("(min-height:1350px)");
  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleSettings = (title: string) => {
    setSettingsPage(title);
  };

  return (
    <>
      <Box sx={{ margin: "0 15px 0 80px" }}>
        <AccountDrawer handleDrawer={handleDrawer} open={open} />
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              margin: "15px 0 0 0",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              Settings
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
            <Box
              sx={{
                position: "relative",
                marginBottom: "35px",
                outline: "none",
                marginRight: "50px",
              }}
            >
              <img
                src={ImgPlaceholder}
                alt={ImgPlaceholder}
                width="100"
                height="100"
                style={{ borderRadius: "50%", border: "4px solid #F39D1B" }}
              />
              <Grid
                component="div"
                sx={{
                  backgroundColor: "#F39D1B",
                  color: "#000",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  left: 65,
                  bottom: 5,
                  cursor: "pointer",
                }}
              >
                <CreateOutlinedIcon onClick={handleOpen} />
                <Modal
                  open={openModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Grid
                    sx={{
                      overflowY: matchesY && matchesX ? "none" : "scroll",
                      position: "absolute" as "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "65%",
                      maxHeight: "55%",
                      bgcolor: "background.paper",
                      boxShadow: 30,
                      padding: "",
                      borderRadius: "15px",
                      outline: 0,
                    }}
                  >
                    <Box>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          padding: "10px 0 10px 10px",
                          backgroundColor: "#00B9D1",
                          color: "#fff",
                          width: "100%",
                        }}
                      >
                        Edit Profile
                      </Typography>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: "#fff",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Box sx={{ padding: "30px" }}>
                      <SettingEditForm
                        user_id={user_id}
                        img={ImgPlaceholder}
                        email={userInfo?.email}
                        firstName={userInfo?.first_name}
                        lastName={userInfo?.last_name}
                        dob={userInfo?.dob}
                        contact={userInfo?.contact}
                      />
                    </Box>
                  </Grid>
                </Modal>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            {options.map((option) => (
              <IconOptionCard
                key={option.id}
                Img={option.img}
                title={option.title}
                route={option.route}
                handleSettings={handleSettings}
              />
            ))}
            <Grid
              item
              sm={12}
              sx={{
                paddingTop: "0px!important",
                boxShadow: "0px 4px 16px 0px rgba(17, 34, 17, 0.05)",
                backgroundColor: "#fff",
                borderRadius: "15px",
              }}
            >
              {settingsPage === "security" ? (
                <>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", padding: "10px" }}
                  >
                    Security
                  </Typography>
                  <SettingsSecurityPage />
                </>
              ) : settingsPage === "address" ? (
                <>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", padding: "10px" }}
                  >
                    Address Info
                  </Typography>
                  <AddressInfoPage />
                </>
              ) : (
                <>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", padding: "10px" }}
                  >
                    Profile Info
                  </Typography>
                  <ProfileInfoPage userInfo={userInfo} />
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Grid
        sx={{
          width: "100vw",
          height: "750px",
          backgroundImage: "linear-gradient( white, #D5EEF5, #D5EEF5)",
          position: "absolute",
          bottom: "0",
          zIndex: "-1",
        }}
      ></Grid>
    </>
  );
};

export default SettingsPage;
