import { Grid } from "@mui/material";
import FormControl from "./FormControl";

const SpecificInfoForm = () => {
  return (
    <Grid container sx={{ marginTop: "40px" }} spacing={4}>
      <Grid
        item
        md={6}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Grid sx={{ width: "45%" }}>
          <FormControl
            control="number"
            type="nightlyBasePrice"
            label="Nightly Base Price"
            name="nightlyBasePrice"
            min="0"
          />
          <FormControl
            control="number"
            type="securityDeposit"
            label="Security Deposit"
            name="securityDeposit"
            min="0"
          />
          <FormControl
            control="number"
            type="cleaningFee"
            label="Cleaning Fee"
            name="cleaningFee"
            min="0"
          />
          <FormControl
            control="number"
            type="minStay"
            label="Minimum Stay"
            name="minStay"
            min="0"
          />
          <FormControl
            control="number"
            type="maxGuests"
            label="Maximum Guests"
            name="maxGuests"
            min="0"
          />
        </Grid>
        <Grid sx={{ width: "45%" }}>
          <FormControl
            control="number"
            type="extraGuestFee"
            label="Extra Guest Fee"
            name="extraGuestFee"
            min="0"
          />
          <FormControl
            control="number"
            type="bedrooms"
            label="Bedrooms"
            name="bedrooms"
            min="0"
          />
          <FormControl
            control="number"
            type="numOfBeds"
            label="Number of Beds"
            name="numOfBeds"
            min="0"
          />
          <FormControl
            control="number"
            type="bathrooms"
            label="Bathrooms"
            name="bathrooms"
            min="0"
          />
        </Grid>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <FormControl
          control="textarea"
          type="description"
          label="Description"
          name="description"
        />
        <FormControl
          control="input"
          type="airbnbId"
          label="Airbnb ID"
          name="airbnbId"
        />
        <FormControl
          control="input"
          type="externalId"
          label="External ID"
          name="externalId"
        />
        <FormControl
          control="textarea"
          type="rules"
          label="Property Rules"
          name="rules"
        />
        <FormControl
          control="textarea"
          type="extra"
          label="Additional Property Information"
          name="extra"
        />
      </Grid>
    </Grid>
  );
};

export default SpecificInfoForm;
