import { createSlice } from "@reduxjs/toolkit";
import { user } from "../../../types/usersTypes";
import {
  getUserSettings,
  updateUserSettings,
  trustSwiftyVerification,
} from "../../thunks/userThunk";

interface UserSlice {
  loading: Boolean;
  error: any;
  response: any;
  currentUser: Object;
  updatedUser: Object;
}

const initialState: UserSlice = {
  loading: false,
  error: null,
  response: null,
  currentUser: {},
  updatedUser: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getUserSettings.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getUserSettings.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
    });
    builder.addCase(getUserSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(updateUserSettings.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(updateUserSettings.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
    });
    builder.addCase(updateUserSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(trustSwiftyVerification.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(trustSwiftyVerification.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
    });
    builder.addCase(trustSwiftyVerification.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const {} = userSlice.actions;

export const userReducer = userSlice.reducer;
