import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utilities/authMidleware";
import { backendURL, config } from "../../utilities/utils";

const loginUser = createAsyncThunk(
  "authentication/login",
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}/api/v1/auth/login`,
        { email, password },
        config
      );

      // store user's token in local storage
      localStorage.setItem("userToken", data.accessToken);

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const registerUser = createAsyncThunk(
  "authentication/register",
  async (
    {
      first_name,
      last_name,
      email,
      password,
    }: {
      first_name: string;
      last_name: string;
      email: string;
      password: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/auth/register`,
        {
          first_name,
          last_name,
          email,
          password,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const logoutUser = createAsyncThunk(
  "authentication/logout",
  async ({ session_id }: { session_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/auth/logout`,
        {
          session_id,
        },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.meassage);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const verifyEmail = createAsyncThunk(
  "authentication/verifyEmail",
  async (
    { verificationToken }: { verificationToken: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/auth/verify-email`,
        {
          verificationToken,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const forgotPassowrd = createAsyncThunk(
  "authentication/forgotPassowrd",
  async ({ email }: { email: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/auth/forgot-password`,
        {
          email,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const resetPassword = createAsyncThunk(
  "authentication/resetPassword",
  async (
    { password, user_id }: { password: string; user_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/auth/reset-password`,
        {
          password,
          user_id,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const forgotPasswordVerification = createAsyncThunk(
  "authentication/forgotPasswordVerification",
  async ({ token }: { token: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/auth/verify-password-token`,
        {
          token,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getSupportUssers = createAsyncThunk(
  "authentication/getSupportUssers",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}/api/v1/auth/getSupportUssers`,
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export {
  loginUser,
  registerUser,
  logoutUser,
  verifyEmail,
  resetPassword,
  forgotPassowrd,
  forgotPasswordVerification,
  getSupportUssers,
};
