import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { backendURL, config } from "../../utilities/utils";
import { displayConsoleError } from "../../utilities/helperFunctions";

const getAllProperties = createAsyncThunk(
  "listings/allProperties",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}/api/v1/property/getAllProperties`,
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllProperties", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPropertiesListings = createAsyncThunk(
  "listings/propertiesListings",
  async ({ value }: { value: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/property/getPropertiesListings`,
        { selectedDestination: value },
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getPropertiesListings", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllPropertyTypes = createAsyncThunk(
  "listings/allPropertyTypes",
  async ({ value }: { value: any }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}/api/v1/property/getAllPropertyTypes`,
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllPropertyTypes", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPropertyType = createAsyncThunk(
  "listings/propertyType",
  async (
    { property_type_id }: { property_type_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/property/getPropertyType`,
        {
          property_type_id,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPropertySpecification = createAsyncThunk(
  "listings/propertySpecification",
  async ({ property_id }: { property_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/property/getPropertySpecifications`,
        {
          property_id,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPropertiesDestination = createAsyncThunk(
  "listings/PropertiesDestination",
  async ({ value }: { value: any }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}/api/v1/property/getPropertiesDestination`,
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getHomeProperties = createAsyncThunk(
  "listings/getHomeProperties",
  async ({ value }: { value: any }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}/api/v1/property/getHomeProperties`,
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export {
  getAllProperties,
  getAllPropertyTypes,
  getPropertyType,
  getPropertySpecification,
  getPropertiesListings,
  getPropertiesDestination,
  getHomeProperties,
};
