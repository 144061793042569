import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Carousel from "react-multi-carousel";

import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import imgPlaceHolder from "../../assets/about-1.jpeg";
import RatingStar from "../../components/reusableComponents/ratingStar/ratingStar";
import ImgPlaceholder from "../../../src/assets/about-3.jpeg";
import {
  dbKeyConverterToLabel,
  specificationsIcons,
} from "../../utilities/utils";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { setPreviousLocation } from "../../store";
import {
  getPropertySpecification,
  getSpecificProperty,
} from "../../store/thunks/propertyThunk";
import { displayConsoleError } from "../../utilities/helperFunctions";

const PropertyDetail = () => {
  const { previousLocation, loggedIn, userInfo } = useAppSelector(
    (state: any) => state.authentication
  );
  const { property } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeAmenities, setActiveAmenities] = useState<string[]>([]);
  const [openAmenities, setOpenAmenities] = useState(false);
  const [allActiveAmenities, setAllActiveAmenities] = useState<string[]>([]);
  const [bookedPropertyDetails, setbookedPropertyDetails] = useState<any>({});

  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (loggedIn) {
      if (property && Number(property)) {
        dispatch(
          getSpecificProperty({
            property_id: property,
          })
        )
          .then(async (propertyResult: any) => {
            if (
              propertyResult.payload?.property_details &&
              propertyResult.payload?.property_details?.length > 0
            ) {
              setbookedPropertyDetails(
                propertyResult.payload?.property_details[0]
              );
              dispatch(
                getPropertySpecification({
                  property_id: property,
                })
              )
                .then(async (propertySpecificationResult: any) => {
                  if (
                    propertySpecificationResult.payload
                      ?.property_specifications &&
                    propertySpecificationResult.payload?.property_specifications
                      ?.length > 0
                  ) {
                    activeAmenitiesView(
                      propertySpecificationResult.payload
                        ?.property_specifications[0]
                    );
                  }
                })
                .catch((error) => {
                  displayConsoleError("getPropertySpecification", error);
                });
            } else {
              navigate("/dashboard");
            }
          })
          .catch((error: any) => {
            displayConsoleError("getSpecificProperty", error);
            navigate("/dashboard");
          });
      }
    } else {
      dispatch(setPreviousLocation(location.pathname));
      navigate("/login");
    }
  }, [navigate, loggedIn]);

  const activeAmenitiesView = (propertySpecifications: any) => {
    let tempActiveAmenities = [];
    setAllActiveAmenities(
      Object.keys(propertySpecifications).filter(
        (key) => propertySpecifications[key] === true
      )
    );

    let amenitiesKeys = Object.keys(propertySpecifications).sort(
      () => 0.5 - Math.random()
    );

    for (const key of amenitiesKeys) {
      if (propertySpecifications[key] === true) {
        if (tempActiveAmenities.length < 8) {
          tempActiveAmenities.push(key);
        }
      }
    }
    setActiveAmenities(tempActiveAmenities);
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleCloseAmenities = () => {
    setOpenAmenities(false);
  };

  const handleOpenAmenities = () => {
    setOpenAmenities(true);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const matchesX = useMediaQuery("(min-width:900px)");

  return (
    <>
      <Box sx={{ margin: "0 15px 0 80px" }}>
        <AccountDrawer handleDrawer={handleDrawer} open={open} />
        <Button
          onClick={() => {
            if (previousLocation?.includes("login")) {
              navigate("/dashboard");
            } else {
              window.history.back();
            }
          }}
          variant="contained"
          sx={{ marginTop: "1%" }}
        >
          Back
        </Button>
        <Grid container sx={{ marginBottom: "35px" }}>
          <Grid
            item
            xs={12}
            style={{
              padding: "15px 0 0 0",
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                textTransform: "capitalize",
                marginBottom: "25px",
              }}
            >
              Property Information
            </Typography>
          </Grid>
          {bookedPropertyDetails &&
            bookedPropertyDetails?.property_images_url && (
              <Carousel
                containerClass="carousel-container"
                itemClass="carousel-item"
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={5000}
              >
                {bookedPropertyDetails?.property_images_url
                  ?.split(",")
                  ?.map((image_url: string, index: number) => {
                    return (
                      <Box key={index} sx={{ width: "100%" }}>
                        <img
                          src={image_url}
                          style={{ width: "100%", height: "350px" }}
                        />
                      </Box>
                    );
                  })}
              </Carousel>
            )}
        </Grid>
        <Box
          sx={{
            paddingBottom: "20px",
            position: "relative",
            margin: matchesX ? "0 -15px 0 -15px" : "0 -15px 0 -30px",
          }}
        >
          <Box
            sx={{
              height: "400px",
              width: "100%",
              zIndex: "-1",
              backgroundImage: "linear-gradient(#D5EEF5, #fff)",
              position: "absolute",
              top: "0",
              left: "0",
            }}
          ></Box>
          <Container sx={{ margin: "0 " }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ margin: "0 0 0 15px" }}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {bookedPropertyDetails.property_name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#00b9d1", margin: "5px 0" }}
                >
                  {bookedPropertyDetails.street_1}
                  {", "}
                  {bookedPropertyDetails.street_2}
                  {bookedPropertyDetails.city}

                  {bookedPropertyDetails.state
                    ? bookedPropertyDetails.state
                    : bookedPropertyDetails.country}
                </Typography>
                {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                  <RatingStar rating={4} color="gold" />
                  <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                    ** Property reviews ** (325 Reviews)
                  </Typography>
                </Box> */}
              </Grid>
              <Grid
                item
                md={7}
                sm={12}
                xs={12}
                sx={{
                  backgroundColor: "#fff",
                  margin: "20px 15px 0 30px",
                  boxShadow: "10px 5px 20px #e6e6e6",
                  padding: matchesX ? "30px 100px 30px 30px" : "15px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "10px", fontWeight: "bold" }}
                  >
                    Orverview
                  </Typography>
                  <Typography variant="body1">
                    {bookedPropertyDetails.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12} sx={{ margin: "0 0 0 15px" }}>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "10px", fontWeight: "bold" }}
                  >
                    Amenities:
                  </Typography>
                  <Grid container>
                    {activeAmenities.map((value: any, index) => {
                      let AmenitiesIcon =
                        specificationsIcons[
                          value as keyof typeof specificationsIcons
                        ];
                      let label: string = dbKeyConverterToLabel(value);
                      return (
                        <Grid
                          item
                          md={6}
                          sm={12}
                          xs={12}
                          key={index}
                          sx={{
                            marginBottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ marginRight: "5px" }}
                          >
                            {<img src={`${AmenitiesIcon}`} />}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {label}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Button
                    variant="contained"
                    onClick={handleOpenAmenities}
                    sx={{ marginTop: "15px" }}
                  >
                    Show All Amenities
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Dialog
        fullWidth
        maxWidth={"xl"}
        open={openAmenities}
        onClose={handleCloseAmenities}
        aria-labelledby="amenities-dialog-title"
        aria-describedby="amenities-dialog"
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", padding: "10px 0 10px 10px" }}
          id="amenities-dialog-title"
        >
          Property Amenities
        </Typography>
        <DialogContent>
          <Grid container spacing={2}>
            {allActiveAmenities.map((value: string, index) => {
              let AmenitiesIcon =
                specificationsIcons[value as keyof typeof specificationsIcons];
              let label: string = dbKeyConverterToLabel(value);
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  sx={{
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <Typography>{<img src={`${AmenitiesIcon}`} />}</Typography>
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {label}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAmenities} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PropertyDetail;
